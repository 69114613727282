import { Socket } from 'socket.io-client';
import { Dispatch } from 'redux';
import { History } from 'history';
// actions
import dyopsAction from '../redux/actions/dyops';
import resultAction from '../redux/actions/result';
// types
import { dyopsType } from '../types/dyops';
// mapping
import { mapping } from '../config/mapping';
import { resultType } from '../types/result';

export const handleDyopsControl = (
  dispatch: Dispatch<any>,
  socket: Socket,
  dyops: dyopsType,
  action: string,
  history: History,
  isIntegration: boolean,
  activeDyopRandom: () => string,
  doctorName: string,
  isDark?: boolean,
): void => {
  // last 3 steps sequence
  const steps = dyops.steps.slice(dyops.steps.length - 3, dyops.steps.length).join('');
  // stop test steps sequence
  const stopSteps = steps === '++-' || steps === '-+-' || steps === '+-+';
  // stop one eye test condition
  const stopTestCondition = dyops.size === 104 || dyops.size === 4 || stopSteps;

  const newAction = stopTestCondition ? (dyops.closeLeftEye ? 'changeEye' : 'stopTest') : action;

  if (newAction === 'stopTest') {
    handleStopTest(history, dispatch, socket, dyops, isIntegration, doctorName, isDark as boolean);
  } else {
    // random active dyop when dyops size changes
    const active = newAction === 'increase' || newAction === 'decrease' ? activeDyopRandom() : dyops.active;

    const payload = mapping.buildDyopsData({ ...dyops, active }, newAction);

    socket.emit('emitUpdateDoctor', { dyops: payload });
    dispatch(dyopsAction.getDyops(payload));
  }
};

const handleStopTest = (
  history: History,
  dispatch: Dispatch<any>,
  socket: Socket,
  dyops: dyopsType,
  isIntegration: boolean,
  doctorName: string,
  isDark: boolean,
): void => {
  const roomId = socket.io.opts.query?.roomId ? socket.io.opts.query.roomId : '';
  const newDyops = {
    ...dyops,
    result: {
      ...dyops.result,
      doctorName: doctorName ? doctorName : '',
      date: Date.now(),
      roomId,
    },
  };

  const payload = mapping.buildDyopsData(newDyops, 'stopTest');

  // callback for patient redirect to results page
  const patientCallback = (isIntegration: boolean, integrationPayload?: resultType) => {
    dispatch(dyopsAction.getDyops(payload));
    socket.emit('emitUpdateDoctor', {
      dyops: isIntegration ? { ...payload, result: integrationPayload } : payload,
    });
  };

  // callback for restarting test and doctor redirect to results page
  const doctorCallback = (isIntegration: boolean, integrationPayload?: resultType): void => {
    // integration callback for iframe parent
    isIntegration && window?.top?.postMessage(JSON.stringify(integrationPayload), '*');

    const handleRestart = (dispatch: any, socket: any, dyops: dyopsType): void => {
      const payload = mapping.buildDyopsData(dyops, doctorName ? 'restart' : 'selfRestart');
      socket.emit('emitUpdateDoctor', {
        dyops: payload,
      });
      dispatch(dyopsAction.getDyops(payload));
    };

    !isIntegration && handleRestart(dispatch, socket, dyops);

    const routeToNavigate = isIntegration ? (doctorName ? '/connect' : '/login') : `/result/${roomId}`;
    const routeToNavigateDart = isIntegration ? '/end_test' : `/result/${roomId}`;
    history.push(isDark ? routeToNavigateDart : routeToNavigate);
  };

  dispatch(resultAction.editResultAction(roomId, payload, isIntegration, patientCallback, doctorCallback));
};
