import React, { FC, useEffect, useLayoutEffect, useMemo, useState } from 'react';
// types
import { appDataType, shoeMetricsType } from '../../../../types/types';
// style
import './index.scss';
// helpers
import { footSizes } from '../../../../config/options';
import Input from '../../../atoms/input';

interface DistanceProps {
  appData: appDataType;
  footSizeUnit: string;
  customSize: string;
  footSteps: number;
  setDistance: (distance: string) => void;
  setFootSizeUnit: (unit: string) => void;
  setCustomSize: (size: string) => void;
  setFootSteps: (steps: number) => void;
  isScroll: boolean;
  setIsScroll: (isScroll: boolean) => void;
  tempDistance: string;
  setIsTapeMeasure: (isTape: boolean) => void;
  setTempDistance: (tempDistance: string) => void;
}

const DistanceNew: FC<DistanceProps> = ({
  appData,
  customSize,
  setIsTapeMeasure,
  setDistance,
  setFootSizeUnit,
  setCustomSize,
  setFootSteps,
  setIsScroll,
  setTempDistance,
}: DistanceProps) => {
  const [isTape, setIsTape] = useState<boolean>(true);

  useLayoutEffect(() => {
    // setIsTape(appData.footSteps && appData.footSizeUnit ? false : distance ? true : false);
  }, []);

  useEffect(() => {
    setDistance(appData.distance);
    setFootSizeUnit(appData.footSizeUnit);
    setCustomSize(appData.customSize);
    setFootSteps(appData.footSteps);
  }, [appData]);

  const handleFootSize = ({ value, label }: { value: string; label: string }) => {
    setCustomSize(value);
    getFootSteps(footSizes, footSizesByUnit, label);
  };

  const getFootSizesByUnit = (unit: string): Array<{ key: number; value: number; label: string }> => {
    const result = footSizes
      .find((item) => item.key === unit)
      ?.items.map((item, index) => {
        return { key: index, value: item, label: `${item} ${unit === 'UK(M)' ? 'men' : 'woman'}` };
      });
    return result || [];
  };

  const footSizesByUnit = useMemo(
    () => [...getFootSizesByUnit('UK(M)'), ...getFootSizesByUnit('UK(W)')].sort((a, b) => a.value - b.value),
    [],
  );

  // footsteps quantity calcultaions
  const getFootSteps = (
    footSizes: Array<{
      key: shoeMetricsType;
      items: Array<number>;
    }>,
    footSizesByUnit: Array<{ key: number; value: number }> | undefined,
    size: string,
  ) => {
    const [sizeToFind, unit] = size.split(' ');

    const key = unit == 'woman' ? 'UK(W)' : 'UK(M)';

    const unitItems = footSizes.find((item) => item.key === key);
    // poisition of foot size in array of sizes
    const position = unitItems?.items?.findIndex((item) => item === Number(+sizeToFind));

    // foot size in centimeters
    const centimeters = footSizes.find((item) => item.key === 'Centimeters')?.items[position ? position : 0];

    const footSteps = centimeters ? Math.ceil(300 / centimeters) : 0;
    const distance = centimeters ? String(((footSteps * (centimeters ? centimeters : 1)) / 100).toFixed(2)) : '1';

    setDistance(appData.unit === 'meter' ? distance : String((Number(distance) * 3.821).toFixed(2)));
    setTempDistance(appData.distance);
    setFootSteps(footSteps);
    setIsScroll(true);
  };

  const handlePressTapeMeasure = () => {
    setIsTape(true);
    setIsTapeMeasure(true);
    setCustomSize('');
    setFootSteps(0);
  };

  const handlePressSteps = () => {
    setIsTape(false);
    setIsTapeMeasure(false);
  };

  return (
    <div className="distanceNew">
      <div className="distanceNew__buttonContainer">
        <button
          className={`btn btn_submit medium outlined ${isTape ? 'selected' : ''} distanceNew__chooseButton`}
          type="button"
          onClick={handlePressTapeMeasure}
        >
          I WILL MEASURE WITH A TAPE MEASURE
        </button>
        <button
          className={`btn btn_submit medium outlined ${isTape ? '' : 'selected'} distanceNew__chooseButton`}
          type="button"
          onClick={handlePressSteps}
        >
          I WILL MEASURE BY TAKING STEPS
        </button>

        {!isTape && (
          <Input
            label=""
            input={customSize}
            id="size"
            placeholder="Choose Shoe Size"
            disabled={false}
            select={true}
            isSearchable={false}
            options={footSizesByUnit}
            handleChange={handleFootSize}
            selectValue={customSize}
          />
        )}
      </div>
    </div>
  );
};

export default DistanceNew;
