import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { apiApp } from '../../api/app';
import IntegrationErrorPage from '../../components/pages/integrationError';
import LoginPage from '../../components/pages/login';
import appAction from '../../redux/actions/app';
import userAction from '../../redux/actions/user';
import { stateType, windowLocation } from '../../types/types';

export const withAuth =
  <T,>(Component: React.FunctionComponent<T>) =>
  // eslint-disable-next-line react/display-name
  (props: any) => {
    const dispatch = useDispatch();
    const location = useLocation<windowLocation>();
    const accessToken = props.isIntegration
      ? sessionStorage.getItem('integrationToken')
      : localStorage.getItem('accessToken');

    const userType = useSelector((state: stateType) => state.user.user.userType);

    const refreshToken = localStorage.getItem('refreshToken');
    const accessTokenCheck = apiApp.decodeToken(accessToken, 'date');
    const refreshTokenCheck = apiApp.decodeToken(refreshToken, 'date');
    const history = useHistory();

    useEffect(() => {
      accessTokenCheck && dispatch(appAction.getAppDataAction(props.isIntegration));
    }, [accessToken]);

    const callback = () => {
      if (!location?.state) {
        history.push(window.location.pathname);
      } else {
        history.push({
          pathname: window.location.pathname,
          state: { roomId: location?.state.roomId },
        });
      }
    };

    const loginRedirect = () => {
      history.push('/login');
    };

    if ((!props.isIntegration && refreshTokenCheck) || (props.isIntegration && accessTokenCheck)) {
      if (!accessTokenCheck && refreshToken && !props.isIntegration) {
        dispatch(appAction.refreshToken(refreshToken ? refreshToken : '', callback, loginRedirect));
        return <h1>Loading...</h1>;
      } else {
        if (!userType.length) {
          dispatch(userAction.getUserAction());
          return <h1>Loading...</h1>;
        }
        return <Component {...props} />;
      }
    } else {
      return props.isIntegration ? <IntegrationErrorPage /> : <LoginPage />;
    }
  };
