import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// components
import Result from '../../moleculs/result';
import TestHistory from '../testHistory';
// types
import { stateType } from '../../../types/types';
import { resultType } from '../../../types/result';
// style
import './index.scss';

interface ResultsProps {
  roomId: string;
  result: resultType;
  patientName: string;
  patientId: number;
  userType: string;
}

const Results: FC<ResultsProps> = ({ roomId, result, patientName, patientId, userType }: ResultsProps) => {
  // store
  const appLoading = useSelector((state: stateType) => state.app.loading);
  const resultsLoading = useSelector((state: stateType) => state.results.loading);
  const doctorUnitMeasure = useSelector((state: stateType) => state.user.user.unitMeasure);
  const patientUnitMeasure = useSelector((state: stateType) => state.app.app.unit);
  const testHistory = useSelector((state: stateType) => state.results.results);

  return (
    <div className="results">
      {!appLoading.status && result.leftEye && result.leftEye.meters ? (
        <div className="results__inner">
          <Result
            result={result}
            roomId={roomId}
            unitMeasure={doctorUnitMeasure ? doctorUnitMeasure : patientUnitMeasure}
            userType={userType}
          />
          {patientId && (
            <TestHistory
              patientName={patientName}
              patientId={patientId}
              unitMeasure={doctorUnitMeasure ? doctorUnitMeasure : patientUnitMeasure}
              loading={resultsLoading}
              results={testHistory}
              disabled={false}
              userType={userType}
            />
          )}
        </div>
      ) : (
        !appLoading.status && <h3 className="page-heading">Result not found</h3>
      )}
    </div>
  );
};

export default Results;
