import { call, delay, put } from 'redux-saga/effects';
// api
import { apiResults } from '../../api/results';
// actions
import resultsAction from '../actions/results';
// types
import { getResultsActionReturnType } from '../../types/actions/results';

export function* sagaGetResults(action: getResultsActionReturnType): any {
  try {
    yield put(resultsAction.resultsLoading(true, 0));
    const resultsResponse = yield call(apiResults.getResults, action.id, action.data);
    yield put(resultsAction.resultsLoading(true, 90));
    yield put(resultsAction.resultsLoading(true, 100));
    yield delay(500);
    yield put(
      resultsAction.getResults({
        ...resultsResponse.data,
        skip: action.data.skip,
        first: action.data.first,
        testResultList: action.data.testResultList
          ? [...action.data.testResultList, ...resultsResponse.data.testResultList]
          : resultsResponse.data.testResultList,
      }),
    );
    yield delay(10);
    yield put(resultsAction.resultsLoading(false, 0));
  } catch (e) {
    console.log(e);
    yield put(resultsAction.resultsLoading(true, 100));
    yield delay(1000);
    yield put(resultsAction.resultsLoading(false, 0));
  }
}
