import { call, put } from 'redux-saga/effects';
// api
import { apiUser } from '../../api/user';
import { getUserActionReturnType } from '../../types/actions/user';
// actions
import userAction from '../actions/user';

export function* sagaGetUser(action: getUserActionReturnType): any {
  try {
    const userResponse = yield call(apiUser.getUser, action.selfTestToken);
    yield put(
      userAction.getUser({
        ...userResponse.data,
        userType: action.userType ? action.userType : userResponse.data.userType,
      }),
    );
  } catch (e) {
    console.log(e);
  }
}
