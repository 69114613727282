import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// types
import { stateType } from '../../../types/types';
// style
import './index.scss';

const Notification: FC = () => {
  const notification = useSelector((state: stateType) => state.app.notification);

  return (
    <div className={`warning ${!notification ? 'hidden' : ''}`}>
      <div className="warning__container">
        <h3>Oops</h3>
        <p>
          Seems like you{`'`}ve changed your viewport settings - it may have impact on the test results, it is better
          now to re-calibrate your screen
        </p>
        <Link to="/calibration">Calibrate</Link>
      </div>
    </div>
  );
};

export default Notification;
