import { call, delay, put } from 'redux-saga/effects';
// api
import { apiPatient } from '../../api/patient';
import { getPatientActionReturnType } from '../../types/actions/patient';
// actions
import patientAction from '../actions/patient';
import resultsAction from '../actions/results';

export function* sagaGetPatient(action: getPatientActionReturnType): any {
  try {
    yield delay(1);
    yield put(patientAction.getPatientClear());
    yield put(patientAction.patientLoading(true, 0));
    const patientResponse = yield call(apiPatient.getPatient, action.id);
    yield put(patientAction.patientLoading(true, 90));
    yield put(patientAction.patientLoading(true, 100));
    yield delay(500);
    yield put(patientAction.patientLoading(false, 0));
    yield put(resultsAction.getResultsAction(String(action.id), { first: 2, skip: 0, letter: '' }));
    yield put(patientAction.getPatient(patientResponse.data.patientInfo[0]));
  } catch (e) {
    console.log(e);
  }
}
