import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// types
import { decodedTokenInfoType, stateType } from '../../types/types';
// routes
import routes from '../../config/routes';
import { withAuth } from '../../hocs/withAuth';

export interface LayoutProps {
  component: React.FunctionComponent<any>;
  path: string | Array<string>;
  exact: boolean;
  location?: { state: { roomId: string } };
  isIntegration: boolean;
  decodedData: decodedTokenInfoType;
}

const Layout: FC<LayoutProps> = ({ component: Component, ...args }: LayoutProps) => {
  const history = useHistory();

  // store
  const userType = useSelector((state: stateType) => state.user.user.userType);
  const calibration = useSelector((state: stateType) => state.app.app.ratio);
  const distance = useSelector((state: stateType) => state.app.app.distance);

  const safeRedirect = () => {
    setTimeout(() => {
      history.push(routes.patient.safeRoute);
    }, 0);
  };

  const defaultRedirect = () => {
    setTimeout(() => {
      history.push(routes.patient.defaultRoute);
    }, 0);
  };

  if (userType === 'patient') {
    // if user doesn't have calibration or distance saved
    if (!calibration && !distance && args.path !== '/calibration') {
      return <>{safeRedirect()}</>;
      // if user doesn't have distance saved
    } else if (!distance && args.path !== '/calibration' && args.path[1] !== '/distance') {
      return <>{defaultRedirect()}</>;
    }
  }
  return <Component {...args} />;
};

export default withAuth<LayoutProps>(Layout);
