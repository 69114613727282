import { call, delay, put } from 'redux-saga/effects';
// api
import { apiApp } from '../../api/app';
import { apiUser } from '../../api/user';
// actions
import appAction from '../actions/app';
import userAction from '../actions/user';
// types
import {
  loginReturnType,
  logoutReturnType,
  registrationReturnType,
  editAppDataActionReturnType,
  refreshTokenReturnType,
  getAppDataActionReturnType,
} from '../../types/actions/app';

export function* sagaAppRegistration(action: registrationReturnType): any {
  try {
    yield put(appAction.appLoading(true, 0));
    yield call(apiApp.registration, action.params);
    yield put(appAction.appLoading(true, 90));
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    yield put(appAction.appLoading(false, 0));
    action.callback();
  } catch (e: any) {
    alert(e.response.data.message);
    yield call(appAction.deleteTokens);
    yield put(appAction.appLoading(true, 100));
    yield delay(1000);
    yield put(appAction.appLoading(false, 0));
  }
}

export function* sagaAppLogin(action: loginReturnType): any {
  try {
    yield put(appAction.appLoading(true, 0));
    const responseLogin = yield call(apiApp.login, action.params);
    yield call(appAction.createToken, responseLogin.data.accessToken, responseLogin.data.refreshToken);
    yield put(appAction.appLoading(true, 90));
    const userResponse = yield call(apiUser.getUser);
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    yield put(appAction.appLoading(false, 0));
    yield put(userAction.getUser(userResponse.data));
    responseLogin.data.userType === 'patient' ? action.patientCallback() : action.doctorCallback();
  } catch (e: any) {
    alert(e.response.data.message);
    yield call(appAction.deleteTokens);
    yield put(appAction.appLoading(true, 100));
    yield delay(1000);
    yield put(appAction.appLoading(false, 0));
  }
}

export function* sagaAppLogout(action: logoutReturnType): any {
  try {
    yield put(appAction.appLoading(true, 0));
    yield call(apiApp.logout);
    yield call(appAction.deleteTokens);
    yield put(appAction.appLoading(true, 90));
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    yield put(appAction.appLoading(false, 0));
    action.callback();
  } catch (e: any) {
    yield put(appAction.appLoading(true, 100));
    yield delay(1000);
    yield put(appAction.appLoading(false, 0));
    alert(e.response.data.message);
  }
}

export function* sagaEditAppData(action: editAppDataActionReturnType): any {
  try {
    if (action.isIntegration) {
      sessionStorage.setItem('appData', JSON.stringify(action.data));
    } else {
      localStorage.setItem('appData', JSON.stringify(action.data));
    }
    const appDataEditResponse = action.isIntegration
      ? sessionStorage.getItem('appData')
      : localStorage.getItem('appData');

    if (appDataEditResponse && appDataEditResponse.length > 0) {
      yield put(appAction.getAppData(JSON.parse(appDataEditResponse)));
    }
    action.callback && action.callback();
  } catch (e) {
    console.log(e);
  }
}

export function* sagaGetAppData(action: getAppDataActionReturnType): any {
  try {
    const appDataResponse = action.isIntegration ? sessionStorage.getItem('appData') : localStorage.getItem('appData');
    if (appDataResponse && appDataResponse.length > 0) {
      yield put(appAction.getAppData(JSON.parse(appDataResponse)));
    }
  } catch (e) {
    console.log(e);
  }
}

export function* sagaAppRefreshToken(action: refreshTokenReturnType): any {
  try {
    const refreshTokenResponse = yield call(apiApp.refreshToken, action.refreshToken);
    yield call(appAction.createToken, refreshTokenResponse.data.accessToken, refreshTokenResponse.data.refreshToken);
    action.callback();
  } catch (e) {
    yield call(appAction.deleteTokens);
    action.loginRedirect();
  }
}
