import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
// components
import PatientTest from '../../organisms/patientTest';
// types
import { stateType, windowLocation } from '../../../types/types';
// helpers
import { handleConnectRoute, useWindowSize } from '../../../tools';
// localization
import strings from '../../../localization';
import { useLocation } from 'react-router-dom';
import AppTemplate from '../../templates/appTemplate';
import './index.scss';
import { useTheme } from '../../../hooks/theme';
interface PatientTestPageProps {
  location: { state?: { roomId: string; testType: string; inputType: string } };
  isIntegration: boolean;
}

const PatientTestPage: FC<PatientTestPageProps> = ({ isIntegration }: PatientTestPageProps) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isUserConnected, setIsUserConnected] = useState<boolean>(false);
  const { state } = useLocation<windowLocation>();

  const history = useHistory();
  const [windowWidth, windowHeight] = useWindowSize();
  const height = windowHeight > windowWidth && isMobile;
  const locationInputType = state?.inputType;
  const { isDark } = useTheme();

  // store
  const appLoading = useSelector((state: stateType) => state.app.loading);
  const dyops = useSelector((state: stateType) => state.dyops.dyops);

  // redirect to connect page if user tried to get to test page without room number
  useEffect(() => {
    const redirect = () => {
      history.push('/connect');
    };

    !state && redirect();
  }, []);

  const handleSubmit = () => {
    if (isDark) {
      history.push({
        pathname: '/end_test',
        state: {
          ...state,
          isPatient: true,
        },
      });
    } else {
      history.push({
        pathname: handleConnectRoute(locationInputType ? locationInputType : ''),
        state,
      });
    }
  };

  const handleCancel = () => {
    history.push({
      pathname: handleConnectRoute(locationInputType ? locationInputType : ''),
      state,
    });
  };

  return (
    <AppTemplate
      className={
        locationInputType === 'selfTest' && !dyops.isStarted && (dyops.closeLeftEye || dyops.closeRightEye)
          ? ' self'
          : !appLoading.status && isConnected
          ? ` test ${isDark ? 'dark' : ''}`
          : ''
      }
      heading=""
      description=""
      height={height}
      isHeaderHidden={isConnected}
      submitLabel={strings.testPage.disconnect}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      submitHidden={!(!appLoading.status && isConnected)}
      isConnectStatusShown={!appLoading.status && isConnected}
      isUserConnected={isUserConnected}
      customUserType={`${locationInputType === 'selfTest' ? 'Second device' : 'Doctor'}`}
    >
      {state && (
        <PatientTest
          state={state}
          appLoading={appLoading}
          isConnected={isConnected}
          setIsConnected={setIsConnected}
          setIsUserConnected={setIsUserConnected}
          height={height}
          dyops={dyops}
          isIntegration={isIntegration}
        />
      )}
    </AppTemplate>
  );
};

export default PatientTestPage;
