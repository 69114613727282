import React, { FC } from 'react';
// components
import Input from '../../atoms/input';
// types
import { resultType } from '../../../types/result';
// style
import './index.scss';
// tools
import { convertUnit, handleDateFormat } from '../../../tools';

interface ResultProps {
  result: resultType;
  roomId: string;
  unitMeasure: string;
  userType: string;
}

const Result: FC<ResultProps> = ({ result, roomId, unitMeasure, userType }: ResultProps) => {
  // multiple value inputs arrays
  const date = [
    { fieldName: 'Date', fieldValue: handleDateFormat(result.date, 'date') },
    { fieldName: 'Time', fieldValue: handleDateFormat(result.date, 'time') },
  ];

  const rightEyeResult = [
    {
      fieldName: unitMeasure === 'meter' ? 'Meters' : unitMeasure === 'feet' ? 'Feet' : 'Decimal',
      fieldValue:
        unitMeasure === 'meter'
          ? result.rightEye.meters
          : unitMeasure === 'feet'
          ? result.rightEye.feet
          : result.rightEye.dec,
    },
    { fieldName: 'Arc min', fieldValue: String(result.rightEye.size) },
  ];

  const leftEyeResult = [
    {
      fieldName: unitMeasure === 'meter' ? 'Meters' : unitMeasure === 'feet' ? 'Feet' : 'Decimal',
      fieldValue:
        unitMeasure === 'meter'
          ? result.leftEye.meters
          : unitMeasure === 'feet'
          ? result.leftEye.feet
          : result.leftEye.dec,
    },
    { fieldName: 'Arc min', fieldValue: String(result.leftEye.size) },
  ];

  const distanceResult = [
    {
      fieldName: 'Meters',
      fieldValue: result.unit === 'meter' ? result.distance : convertUnit('feet', result.distance),
    },
    {
      fieldName: 'Feet',
      fieldValue: result.unit === 'feet' ? result.distance : convertUnit('meter', result.distance),
    },
  ];

  return (
    <div className="result-container">
      <div className="result-container__heading">
        <span className="page-heading small">
          {result.inputType === 'selfTest' ? 'Self' : 'Doctor'} Test Result #{roomId}
        </span>
        {result.doctorName && <span className="page-heading small">Doctor name: {result.doctorName}</span>}
      </div>
      <div className="result-container__inner">
        <div className="result-container__item">
          <Input
            label="Patient Name"
            input={result.patientName ? result.patientName : 'PatientName'}
            id="patient-name"
            disabled={true}
            type="standart"
          />
        </div>
        <div className="result-container__item">
          <Input
            label="Date and Time of Test"
            input="input"
            id="result-date"
            disabled={true}
            type="standart"
            multipleValue={date}
          />
        </div>
        <div className="result-container__item">
          <Input
            label="Distance from monitor "
            input={`${unitMeasure === result.unit ? result.distance : convertUnit(unitMeasure, result.distance)} ${
              unitMeasure === 'meter' ? (Number(result.distance) > 1 ? 'Meters' : 'Meter') : 'Feet'
            }`}
            id="result-distance"
            disabled={true}
            type="standart"
            multipleValue={userType === 'doctor' ? distanceResult : undefined}
          />
        </div>
        <div className="result-container__item">
          <Input
            label="Right Eye Visual Acuity "
            input="input"
            id="result-right-eye"
            disabled={true}
            type="standart"
            multipleValue={rightEyeResult}
          />
        </div>
        <div className="result-container__item">
          <Input
            label="Left Eye Visual Acuity "
            input="input"
            id="result-left-eye"
            disabled={true}
            type="standart"
            multipleValue={leftEyeResult}
          />
        </div>
        <div className="result-container__item">
          <Input
            label="Result of Test"
            input={result.resume}
            id="result-resume"
            disabled={true}
            type="standart"
            success={result.resume !== 'Refer'}
          />
        </div>
      </div>
    </div>
  );
};

export default Result;
