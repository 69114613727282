import React, { FC, useEffect, useRef, useState } from 'react';
import NoSleep from 'nosleep.js';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
// components
import AppTemplate from '../../templates/appTemplate';
import SelfTest from '../../organisms/selfTest';
// actions
import dyopsAction from '../../../redux/actions/dyops';
// types
import { appTheme, roomDataType, stateType } from '../../../types/types';
import { dyopsType } from '../../../types/dyops';
// mapping
import { mapping } from '../../../config/mapping';
// websockets
import { webSocketConnect } from '../../../websocket/websocket';
// localization
import strings from '../../../localization';
import appAction from '../../../redux/actions/app';

interface SelfTestPageProps {
  computedMatch: {
    params: { roomId: string; selfTestToken: string; isIntegration: string; theme: appTheme };
  };
}

const SelfTestPage: FC<SelfTestPageProps> = ({ computedMatch: { params } }: SelfTestPageProps) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isUserConnected, setIsUserConnected] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const socket = useRef<any>();
  const noSleep = new NoSleep();
  const socketUrl = process.env.REACT_APP_API_SOCKET_URL || '';

  // store
  const { appLoading, dyops, appData } = useSelector((state: stateType) => ({
    appLoading: state.app.loading,
    dyops: state.dyops.dyops,
    appData: state.app.app,
  }));

  useEffect(() => {
    noSleep.enable();

    dispatch(appAction.editAppDataAction({ ...appData, theme: params.theme }, true));

    if (params.theme === 'dark') {
      const root = document.getElementById('root');
      root?.setAttribute('data-theme', 'dark');
    }

    socket.current = webSocketConnect(socketUrl, params.roomId, params.selfTestToken);

    socket.current.on('connect', () => {
      console.log('connected to ' + socket.current.io.opts.query.roomId);
      setIsConnected(true);

      dispatch(dyopsAction.getDyopsAction(socket.current.io.opts.query.roomId, dyops, startTest));
    });

    socket.current.on('roomStatus', ({ room }: roomDataType) => {
      const patient = room.users.find((item) => item.type !== 'doctor');
      setIsUserConnected(patient && patient.connections > 1 ? true : false);
    });

    socket.current.on('disconnect', () => {
      console.log('disconnected from ' + socket.current.io.opts.query.roomId);
      dispatch(dyopsAction.clearDyops());
      setIsConnected(false);
    });

    const redirect = () => {
      history.push('/qr_scan');
    };

    !params && redirect();

    return () => {
      noSleep.disable();
      socket.current.disconnect();
    };
  }, []);

  const startTest = (dyops: dyopsType) => {
    socket.current.emit('emitUpdateDoctor', {
      dyops: mapping.buildDyopsData(dyops, 'start'),
    });
    dispatch(dyopsAction.getDyops(mapping.buildDyopsData(dyops, 'start')));
  };

  const handleRestart = (dyops: dyopsType) => {
    socket.current.emit('emitUpdateDoctor', {
      dyops: mapping.buildDyopsData(dyops, 'selfRestart'),
    });
    dispatch(dyopsAction.getDyops(mapping.buildDyopsData(dyops, 'selfRestart')));
  };

  const handleSubmit = () => {
    (dyops.closeRightEye || dyops.closeLeftEye) && dyops.isStarted ? handleRestart(dyops) : startTest(dyops);
  };

  const handleCancel = () => {
    history.push({
      pathname: '/login',
    });
  };

  return (
    <AppTemplate
      submitLabel={
        (dyops.closeRightEye || dyops.closeLeftEye) && dyops.isStarted
          ? strings.selfTest.control.restartTest
          : dyops.closeLeftEye
          ? strings.selfTest.testRight
          : strings.selfTest.testLeft
      }
      disabled={!isUserConnected}
      submitHidden={appLoading.status || !isConnected}
      heading=""
      description={appLoading.status || !isConnected || !dyops.isStarted ? '' : strings.selfTest.control.description}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      cancelHidden={params.theme === 'dark'}
      isConnectStatusShown={!appLoading.status && isConnected}
      isUserConnected={isUserConnected}
      customUserType="Second device"
      connectionLabelHidden={true}
      isDevice
      className=" small-content"
      isIntegration={params.isIntegration ? true : false}
    >
      {params && (
        <SelfTest
          socket={socket}
          appLoading={appLoading}
          theme={params.theme}
          dyops={dyops}
          isConnected={isConnected}
          isIntegration={params.isIntegration}
        />
      )}
    </AppTemplate>
  );
};

export default SelfTestPage;
