import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
// components
import FormDistance from '../../organisms/forms/formDistance';
import DistanceResult from '../../moleculs/distanceResult';
// types
import { appDataType, shoeMetricsType } from '../../../types/types';
// style
import './index.scss';
// helpers
import { footSizes } from '../../../config/options';

interface DistanceProps {
  appData: appDataType;
  distance: string;
  footSizeUnit: string;
  customSize: string;
  footSteps: number;
  setDistance: (distance: string) => void;
  setFootSizeUnit: (unit: string) => void;
  setCustomSize: (size: string) => void;
  setFootSteps: (steps: number) => void;
  isScroll: boolean;
  setIsScroll: (isScroll: boolean) => void;
  tempDistance: string;
  setTempDistance: (tempDistance: string) => void;
}

const Distance: FC<DistanceProps> = ({
  appData,
  distance,
  footSizeUnit,
  customSize,
  footSteps,
  setDistance,
  setFootSizeUnit,
  setCustomSize,
  setFootSteps,
  isScroll,
  setIsScroll,
  tempDistance,
  setTempDistance,
}: DistanceProps) => {
  const [isTape, setIsTape] = useState<boolean>(false);

  useLayoutEffect(() => {
    setIsTape(appData.footSteps && appData.footSizeUnit ? false : distance ? true : false);
  }, []);

  useEffect(() => {
    setDistance(appData.distance);
    setFootSizeUnit(appData.footSizeUnit);
    setCustomSize(appData.customSize);
    setFootSteps(appData.footSteps);
  }, [appData]);

  const handleDistanceChange = (value: string) => {
    customSize && setCustomSize('');
    footSteps && setFootSteps(0);
    footSizeUnit && setFootSizeUnit('');
    setDistance(Number(value) < 21 && value.length <= 5 ? value : distance);
  };

  // tape or footstep measurements handler
  const handleTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsTape(Number(e.target.value) === 1 ? true : false);
  };

  // handle unit of foot size measurement(eu, us etc)
  const handleFootSizeUnit = ({ value }: { value: string }) => {
    setFootSizeUnit(value);
    setCustomSize('');
    setFootSteps(0);
    setDistance('');
  };

  // handle foot size measurement (44, 45 etc)
  const handleFootSize = ({ value }: { value: string }) => {
    setCustomSize(value);
    getFootSteps(footSizes, footSizesByUnit, value);
  };

  // foot sizes by unit of measurement method
  const getFootSizesByUnit = (unit: string): Array<{ key: number; value: number; label: number }> | undefined => {
    const result = footSizes
      .find((item) => item.key === unit)
      ?.items.map((item, index) => {
        return { key: index, value: item, label: item };
      })
      .filter((item) => item.value > 0);

    return result;
  };

  // array of foot sizes by one unit of measurement
  const footSizesByUnit = getFootSizesByUnit(footSizeUnit);

  // footsteps quantity calcultaions
  const getFootSteps = (
    footSizes: Array<{
      key: shoeMetricsType;
      items: Array<number>;
    }>,
    footSizesByUnit: Array<{ key: number; value: number }> | undefined,
    size: string,
  ) => {
    // poisition of foot size in array of sizes
    const position = footSizesByUnit?.findIndex((item) => item.value === Number(size));

    // foot size in centimeters
    const centimeters = footSizes.find((item) => item.key === 'Centimeters')?.items[position ? position : 0];

    const footSteps = centimeters ? Math.ceil(300 / centimeters) : 0;
    const distance = centimeters ? String(((footSteps * (centimeters ? centimeters : 1)) / 100).toFixed(2)) : '1';

    setDistance(appData.unit === 'meter' ? distance : String((Number(distance) * 3.821).toFixed(2)));
    setFootSteps(footSteps);
    setIsScroll(true);
  };

  return (
    <div className="distance">
      <FormDistance
        handleDistanceChange={handleDistanceChange}
        distance={distance}
        unit={appData.unit}
        isTape={isTape}
        handleTypeChange={handleTypeChange}
        handleFootSizeUnit={handleFootSizeUnit}
        handleFootSize={handleFootSize}
        footSizesByUnit={footSizesByUnit}
        customSize={customSize}
        footSizeUnit={footSizeUnit}
        setIsScroll={setIsScroll}
        tempDistance={tempDistance}
        setTempDistance={setTempDistance}
      />
      <DistanceResult
        distance={distance === tempDistance ? distance : tempDistance}
        unit={appData.unit}
        isTape={isTape}
        footSteps={footSteps}
        isScroll={isScroll}
        setIsScroll={setIsScroll}
      />
    </div>
  );
};

export default Distance;
