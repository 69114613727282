import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
// api
import { apiApp } from '../../api/app';
// actions
import appAction from '../../redux/actions/app';
import userAction from '../../redux/actions/user';
// types
import { stateType } from '../../types/types';
// localization
import strings from '../../localization';
interface SelfTestLayoutProps {
  component: React.FunctionComponent<any>;
  path: string | Array<string>;
  exact: boolean;
  computedMatch?: { params: { selfTestToken: string; isIntegration: string; language: string } };
}

const SelfTestLayout: FC<SelfTestLayoutProps> = ({ component: Component, ...args }: SelfTestLayoutProps) => {
  const dispatch = useDispatch();
  const history = useHistory();

  // store
  const userType = useSelector((state: stateType) => state.user.user.userType);
  const language = args.computedMatch?.params.language || 'en';

  const selfTestToken = args.computedMatch?.params.selfTestToken;
  const refreshToken = localStorage.getItem('refreshToken');
  const accessToken = localStorage.getItem('accessToken');

  // token check expiration and userType
  const selfTestTokenCheck = apiApp.decodeToken(selfTestToken ? selfTestToken : '', 'userType');

  useEffect(() => {
    if (selfTestToken) appAction.createSelfTestToken(selfTestToken);

    if (args.computedMatch?.params.isIntegration) {
      strings.setLanguage(language);
    }
  }, []);

  // appData for integration
  useEffect(() => {
    strings.setLanguage(language);
  }, [language]);

  // make these methods more dynamic by accessing routes with [] for more userTypes
  const otherUserRedirect = () => {
    setTimeout(() => {
      history.push('/');
    }, 0);
  };

  return (
    <>
      <Route
        render={() => {
          // if selfTestToken is not expired, has userType and no usual users tokens in localStorage
          if (selfTestTokenCheck && !accessToken && !refreshToken) {
            // check if there is user info in Redux store
            if (!userType.length) {
              dispatch(userAction.getUserAction('patientRemote', selfTestToken));
              return <h1>Loading...</h1>;
            } else {
              return (
                <Route
                  exact={args.exact}
                  path={args.path}
                  render={() => {
                    return <Component {...args} />;
                  }}
                />
              );
            }
          }
          // if there is no token in localStorage
          else {
            return <>{otherUserRedirect()}</>;
          }
        }}
      />
    </>
  );
};

export default SelfTestLayout;
