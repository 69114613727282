import React, { FC, useEffect, useRef } from 'react';
// style
import './index.scss';
// localization
import strings from '../../../localization';

interface DistanceResultProps {
  distance: string;
  unit: string;
  isTape: boolean;
  footSteps: number;
  isSelf?: boolean;
  isScroll?: boolean;
  setIsScroll?: (isScroll: boolean) => void;
}

const DistanceResult: FC<DistanceResultProps> = ({
  distance,
  unit,
  isTape,
  footSteps,
  isSelf,
  isScroll,
  setIsScroll,
}: DistanceResultProps) => {
  useEffect(() => {
    isScroll && Number(distance) > 0 && scrollToBottom();
    setTimeout(() => {
      setIsScroll && setIsScroll(false);
    }, 0);
  }, [isScroll, distance]);

  const distanceResultEndRef = useRef<any>(null);

  // scroll to bottom method
  const scrollToBottom = () => {
    distanceResultEndRef.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  return (
    <div className={`distance-result${isSelf ? ' self' : ''}`} ref={distanceResultEndRef}>
      <div className="distance-result__inner">
        {!isSelf && (
          <>
            <h3 className="page-heading small">
              {isTape
                ? strings.distancePage.distanceBlock.heading.viewingDistance
                : strings.distancePage.distanceBlock.heading.viewingSteps}
            </h3>
            <span className="page-description">
              {isTape
                ? strings.distancePage.distanceBlock.description.distanceDescription
                : strings.distancePage.distanceBlock.description.stepsDescription}
            </span>
          </>
        )}
        <div className="distance-result__content">
          <div className="icon-monitor" />
          <div className={`distance-result__content-right ${!isTape ? ' distance-result__content-right_big' : ''}`}>
            <div className={`${isTape ? 'icon-ruler' : 'icon-footsteps'}`} />
            {!isSelf && (
              <span className="distance-result__text">{`${
                isTape
                  ? `${
                      distance && unit
                        ? `${distance} ${
                            unit === 'meter'
                              ? Number(distance) > 1
                                ? strings.distancePage.labels.meters
                                : strings.distancePage.labels.meter
                              : strings.distancePage.labels.feet
                          }`
                        : strings.distancePage.labels.distance
                    }`
                  : `${
                      footSteps
                        ? footSteps + ` ${strings.distancePage.labels.steps}`
                        : strings.distancePage.distanceBlock.chooseSize
                    }`
              }`}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DistanceResult;
