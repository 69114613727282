import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// api
import { apiApp } from '../../../api/app';
// components
import AppTemplate from '../../templates/appTemplate';
import QrConnect from '../../organisms/qrConnect';
// actions
import dyopsAction from '../../../redux/actions/dyops';
// types
import { decodedTokenInfoType, roomDataType, stateType, windowLocation } from '../../../types/types';
// websockets
import { webSocketConnect } from '../../../websocket/websocket';
// mapping
import { mapping } from '../../../config/mapping';
import appFlow from '../../../redux/actions/appFlow';
import { useTheme } from '../../../hooks/theme';
import QrConnectDark from '../../organisms/qrConnect/qrConntectDark';

interface QrConnectPageProps {
  location?: { state?: { testType: string; inputType: string } };
  isIntegration: boolean;
  decodedData: decodedTokenInfoType;
}

const QrConnectPage: FC<QrConnectPageProps> = ({ isIntegration, decodedData }: QrConnectPageProps) => {
  const [roomId, setRoomId] = useState<string>('');
  const [selfTestToken, setSelfTestToken] = useState<string>('');
  const { state } = useLocation<windowLocation>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { isDark } = useTheme();
  const socketRef = useRef<any>();
  const locationTestType = state?.testType;
  const locationInputType = state?.inputType;

  const { flowRoutes, flowIndex, appData, user } = useSelector((state: stateType) => ({
    flowRoutes: state.appFlow.routes,
    flowIndex: state.appFlow.flowIndex,
    appData: state.app.app,
    user: state.user.user,
  }));

  const QrConnectComponent = isDark ? QrConnectDark : QrConnect;

  // websockets
  const accessToken =
    isIntegration && sessionStorage.getItem('integrationToken')
      ? sessionStorage.getItem('integrationToken')
      : localStorage.getItem('accessToken')
      ? localStorage.getItem('accessToken')
      : '';

  const socketUrl = process.env.REACT_APP_API_SOCKET_URL || '';

  const getRoomId = () => {
    apiApp.getRoomId().then((res) => setRoomId(res.data.roomId));
    apiApp.getToken().then((res) => setSelfTestToken(res.data.user_auxilary_access_token));
  };

  useEffect(() => {
    const redirect = () => {
      history.push('/input_type');
    };

    (!locationTestType || !locationInputType) && redirect();

    getRoomId();
  }, []);

  useEffect(() => {
    if (roomId) {
      socketRef.current = webSocketConnect(socketUrl, roomId, accessToken ? accessToken : '');

      const { current: socket } = socketRef;

      socket.on('connect', () => {
        console.log('connected to ' + socket.io.opts.query.roomId);
        // send patient distance value

        const patientInfo = {
          distance: appData.distance,
          unit: appData.unit,
          footSteps: appData.footSteps,
          inputType: locationInputType,
          user,
        };
        const payload = mapping.buildPatientInfo(patientInfo, isIntegration);
        socket.emit('emitUpdatePatient', payload);

        dispatch(dyopsAction.getDyopsAction(socket.io.opts.query.roomId));
      });

      socket.on('roomStatus', ({ room }: roomDataType) => {
        const patient = room.users.find((item) => item.type !== 'doctor');
        patient && patient.connections > 1 && handleSubmit();
      });

      socket.on('disconnect', () => {
        console.log('disconnected from ' + socket.io.opts.query.roomId);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [roomId]);

  const handleSubmit = () => {
    const callback = () => {
      history.push({
        pathname: '/test',
        state: state ? { ...state, roomId } : { roomId },
      });
    };

    dispatch(dyopsAction.checkRoomAction(roomId, callback));
  };

  const handleCancel = () => {
    let route;

    if (isIntegration) {
      dispatch(appFlow.setFlowIndex(flowIndex - 1));
      route = flowRoutes[flowIndex - 1];
    } else {
      route = '/input_type';
    }

    history.push({
      pathname: route,
      state: {
        testType:
          (isIntegration && !decodedData.inputType) || (isIntegration && !decodedData.testType) ? locationTestType : '',
        inputType:
          (isIntegration && !decodedData.inputType) || (isIntegration && !decodedData.testType)
            ? locationInputType
            : '',
      },
    });
  };

  return (
    <AppTemplate
      submitHidden={true}
      handleCancel={handleCancel}
      submitLabel="Start test"
      heading=""
      description=""
      className=" small-content"
      decodedData={decodedData}
      isIntegration={isIntegration}
    >
      {roomId && selfTestToken && (
        <QrConnectComponent roomId={roomId} selfTestToken={selfTestToken} isIntegration={isIntegration} />
      )}
    </AppTemplate>
  );
};

export default QrConnectPage;
