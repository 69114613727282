const fr = {
  signIn: 'Se connecter',
  email: 'Email',
  password: 'Mot de passe',
  patient: 'Je suis un patient',
  doctor: 'Je suis un médecin',
  isAccount: 'Vous êtes déjà inscrit',

  header: {
    navigation: {
      calibrate: 'Paramètrons',
      setDistance: 'Définir la distance',
      testType: 'Type de test',
      inputType: 'Préciser le type',
    },
    headerMenu: {
      settings: 'Parametres',
      aboutDyop: 'À propos',
      help: 'Aide',
    },
  },
  calibrationPage: {
    descriptionNew: [
      'Place a loyalty card or bank card on the screen in the area indicated.',
      'Press the plus and minus buttons until the loyalty card, displayed on the screen, is the same size as the actual loyalty card.',
      'Press next to continue.',
    ],
    description: {
      desktopInstructionInch:
        "Ajustez la distance entre les deux lignes en cliquant sur le bouton +/-. La distance entre elles doit être exactement de 3,37 pouces. Pour effectuer la mesure, vous pouvez utiliser le côté long d'une carte de crédit ou de débit ou une règle.",
      desktopInstructionMM:
        "Ajustez la distance entre les deux lignes en cliquant sur le bouton +/-. La distance entre elles doit être exactement de 85,6 mm. Pour effectuer la mesure, vous pouvez utiliser le côté long d'une carte de crédit ou de débit ou une règle.",
      mobileInstructionInch:
        "Ajustez la distance entre les deux lignes en cliquant sur le bouton +/-. La distance entre elles doit être exactement de 2,12 pouces. Pour effectuer cette mesure, vous pouvez utiliser le côté court d'une carte de crédit ou de débit ou une règle..",
      mobileInstructionMM:
        "Ajustez la distance entre les deux lignes en cliquant sur le bouton +/-. La distance entre elles doit être exactement de 53,98 mm. Pour effectuer la mesure, vous pouvez utiliser le côté court d'une carte de crédit ou de débit ou une règle",
      distanceInstruction:
        "Mesurez la distance entre votre écran et l'endroit où vous vous tenez. De préférence, plus de 2,5 mètres",
    },
    monitorCalibration: 'MONITOR CALIBRATION',
    buttons: {
      euMetrics: 'EU metrics',
      usMetrics: 'US metrics',
      reset: 'Réinitialiser',
    },
  },
  distancePage: {
    description:
      "Déterminons la distance à l'écran soit à partir d'un ruban mètre soit en comptant les pas vous séparant de l'écran",
    buttons: {
      yes: 'Oui',
      no: 'Non',
    },
    labels: {
      isTapeMeasure: "Disposez vous d'un ruban mètre?",
      whatDistance: 'Quelle est la distance?',
      meters: 'Mètres',
      meter: 'Mètre',
      feet: 'Pieds',
      distance: 'Distance',
      steps: 'pas',
    },
    select: {
      chooseOption: 'Faites votre choix',
      europe: 'Europe',
      usMen: 'USA et Canada (Hommes)',
      usWomen: 'USA et Canada (Femmes)',
      shoeMetrics: 'Mesure de la chaussure',
      shoeSize: 'Quelle est votre pointure?',
    },
    distanceBlock: {
      heading: {
        viewingDistance: 'Définissons la distance',
        viewingSteps: 'Réglons la distance en nombre de pas',
      },
      description: {
        distanceDescription: "Calculons la distance à laquelle vous devez vous tenir à partir de l'écran",
        stepsDescription: 'Mesurons le nombre de pas que vous devrez faire depuis votre ordinateur',
      },
      chooseSize: 'Quelle est votre pointure',
    },
  },

  footer: {
    nextStep: 'Suivant',
    back: 'Précédent',
  },
  testTypePage: {
    description: 'Sélectionnez qui va faire le test de vue Dyop',
    options: {
      adult: 'Un adulte',
      child: 'Un enfant',
      infant: 'Un bébé',
    },
  },
  inputTypePage: {
    description: 'Choisissez qui déterminera les tailles de diamètre du cercle DYOP.',
    options: {
      doctorTest: 'Le médecin conduira le test',
      selfTest: 'Je contrôlerai le test avec mon appareil mobile',
      oneDeviceTest: 'Je contrôlerai le test avec les boutons sur l’écran',
    },
  },
  connectPage: {
    heading: {
      headingDoctorTest: 'Le médecin conduira le test',
      headingScanQr: 'Scannez le QR code',
      headingDoctorControl: 'Envoyez le code à votre patient',
    },
    description: {
      doctorTestDescription:
        "Le critère d'acuité visuelle est le plus petit diamètre du cercle Dyop où la direction de la rotation peut être détectée. Dites au médecin si c'est le Dyop gauche ou le Dyop droit et son sens de rotation",
      scanQrDescription: 'Scannez le QR code pour démarrer le test.',
      scanQrDescriptionDark:
        'Scan this QR code to use your mobile device \ as an answering device for the vision screening test',
      doctorControlDescription:
        "Expliquez au patient qu'un Dyop est une cible visuelle en rotation et que la capacité à détecter le sens de rotation du plus petit diamètre (largeur d'arc) du Dyop est un indicateur de référence de l'acuité visuelle. Lorsqu'il se connecte au programme, il doit entrer le numéro de code suivant et appuyer sur Démarrer.",
    },
    labels: {
      enterDoctorCode: 'Saisir le code fourni par votre médecin',
      enterCode: 'Entrer le code',
      startTest: 'Démarrer le test',
      sendCode: 'Envoyer ce code à votre patient et cliquer sur démarrer',
      generateNew: 'Créer un nouveau code',
      note: 'Note: veuillez mettre votre appareil mobile en mode paysage avant de démarrer le test',
    },
  },
  testPage: {
    patientIsNotConnected: "Le patient n'est pas connecté",
    patientConnected: 'Le patient est connecté',
    doctorIsNotConnected: "Le médecin n'est pas connecté",
    doctorConnected: 'Le médecin est connecté',
    secondDeviceConnected: 'Le second appareil est connecté',
    secondDeviceIsNotConnected: "Le second appareil n'est pas connecté",
    disconnect: 'Déconnecter',
    left: 'Gauche',
    right: 'Droite',
    controls: {
      start: 'Démarrer',
      pause: 'Pause',
      incorrect: 'Incorrect',
      correct: 'Correct',
      notSure: 'Ne sait pas',
      direction: 'Direction',
      toLeft: 'À gauche',
      toRight: 'À droite',
      restart: 'Recommencer',
      testLeftEye: "Tester l'oeil gauche",
      testRightEye: "Tester l'oeil droit",
    },
    eyePrepare: {
      pleaseCloseLeft: "Veuillez fermer l'oeil gauche",
      pleaseCloseRight: "Veuillez fermer l'oeil droit",
    },
  },
  selfTest: {
    heading: 'Scannez le QR code',
    description: 'Scannez le QR code pour commencer le test.',
    successScan: 'Scan du QR code réalisé',
    errorScan: 'Impossibilité de scanner le QR code',
    successDescription:
      'Veuillez préciser si le cercle Dyop de droite ou de gauche est en rotation et dans quel sens il tourne',
    closeLeft: 'Fermez l’oeil gauche pour tester l’oeil droit',
    closeRight: 'Fermez l’oeil droit pour tester l’oeil gauche',
    testRight: 'Testons l’oeil droit',
    testLeft: 'Testons l’oeil gauche',
    leftEyeTest: 'Left Eye Test',
    rightEyeTest: 'Right Eye Test',
    pleaseTake: 'Please take',
    stepsAway: 'steps away from your screen and press TEST RIGHT EYE on your answering device to continue.',
    noLongerNeed:
      'You no longer need to enter any more information on your PC/Laptop and you should continue on your phone.',
    distanceYourSelf: 'Please distance yourself',
    fromMonitor: 'from the monitor and press TEST RIGHT EYE on your answering device to continue.',
    control: {
      description: 'Précisez si vous distinguez quel cercle Dyop à gauche ou à droite est en rotation.',
      dontKnow: 'Je ne sais pas',
      restartTest: 'Redemarrer le test',
    },
    eyePrepare: {
      please: 'S’il vous plait',
      take: 'reculer' /*s\'éloigner de 2 pas*/,
      move: 'déplacer' /*se déplacer à 2 mètres*/,
      heelToToe: 'pas (du talon à la pointe du pied)',
      awayFrom: 'à distance de l’écran',
    },
  },
};

export default fr;
