import React, { FC, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
// components
import DateInput from './dateInput';
// style
import './index.scss';
// localization
import strings from '../../../localization';

interface InputProps {
  label: string;
  input: string | { day: string; month: string; year: string };
  id: string;
  disabled: boolean;
  name?: string;
  type?: 'full' | 'centered' | 'standart';
  success?: boolean;
  select?: boolean;
  isSearchable?: boolean;
  options?: Array<{ key: string | number; value: string | number; label: string | number }>;
  handleChange?: any;
  selectValue?: string;
  multipleValue?: Array<{ fieldName: string; fieldValue: string }>;
  placeholder?: string;
  icon?: string;
  required?: boolean;
  inputType?: 'text' | 'number' | 'password' | 'email' | 'date';
  min?: number;
  max?: number;
}

// @ts-ignore
const Input: FC<InputProps> = ({
  label,
  input,
  id,
  disabled,
  type,
  name,
  success,
  select,
  isSearchable,
  options,
  handleChange,
  selectValue,
  multipleValue,
  placeholder,
  icon,
  required,
  inputType,
  min,
  max,
}: InputProps) => {
  const { Option } = components;

  // custom select chosen value with icon
  const SingleValue = (props: any) => {
    return (
      <components.SingleValue {...props}>
        {props.data.chipLabel && <div className={`icon icon-${props.data.icon}`} />}
        {props.data.chipLabel ? props.data.chipLabel : props.data.label}
      </components.SingleValue>
    );
  };
  // custom select option with icon
  const IconOption = (props: any) => (
    <Option {...props}>
      {props.data.chipLabel && <div className={`icon icon-${props.data.icon}`} />}
      {props.data.label}
    </Option>
  );
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 1500);
  }, []);

  return (
    <div
      className={`input-container${type ? ` input-container_${type}` : ''}${
        success ? ' success' : success !== undefined && !success ? ' error' : ''
      }`}
    >
      <label className="label" htmlFor={id}>
        {label && <span className={`label__name${handleChange || type ? '' : ' disabled'}`}>{label}</span>}
        {/*select*/}
        {select && options ? (
          <>
            <Select
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                SingleValue,
                Option: IconOption,
              }}
              className={`select ${id}`}
              classNamePrefix="select"
              menuShouldScrollIntoView={true}
              menuPlacement="auto"
              inputId={id}
              name={id}
              value={input ? options.find((item) => item.value.toString() === input) : null}
              options={options}
              placeholder={placeholder ? placeholder : strings.distancePage.select.chooseOption}
              isDisabled={disabled}
              isSearchable={isSearchable}
              onChange={handleChange}
              defaultValue={options.find((item) => item.value == selectValue)}
            />
            <div ref={messagesEndRef} />
          </>
        ) : // multiple value input
        multipleValue ? (
          <div className="input input_multiple">
            {multipleValue.map((item, index) => (
              <div key={index}>
                <span className="input__field-name">{item.fieldName}</span>:
                <span className="input__field-value">{item.fieldValue}</span>
              </div>
            ))}
          </div>
        ) : // date input
        inputType === 'date' && typeof input !== 'string' ? (
          <DateInput
            id={id}
            handleChange={handleChange}
            value={input}
            disabled={disabled}
            required={required ? required : false}
          />
        ) : (
          typeof input === 'string' && (
            // usual input
            <div className="input__outer">
              <input
                name={name}
                id={id}
                className={`input${handleChange ? '' : ' disabled'}`}
                type={inputType ? inputType : 'text'}
                value={input}
                disabled={disabled}
                onChange={handleChange}
                placeholder={placeholder}
                required={required}
                minLength={min}
                maxLength={max}
                autoComplete="off"
              />
              {icon && <div className={`icon icon-${icon}`} />}
            </div>
          )
        )}
      </label>
    </div>
  );
};

export default Input;
