import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// components
import RadioGroup from '../../organisms/radioGroup';
import AppTemplate from '../../templates/appTemplate';
// types
import { decodedTokenInfoType, stateType, windowLocation } from '../../../types/types';
// helpers
import { handleConnectRoute } from '../../../tools';
// mapping
import { mapping } from '../../../config/mapping';
// localization
import strings from '../../../localization';
import { useDispatch, useSelector } from 'react-redux';
import appFlow from '../../../redux/actions/appFlow';

interface InputTypePageProps {
  location?: { state?: { testType: string; inputType: string } };
  isIntegration: boolean;
  decodedData: decodedTokenInfoType;
}

const InputTypePage: FC<InputTypePageProps> = ({ decodedData, isIntegration }: InputTypePageProps) => {
  const [customInputType, setCustomInputType] = useState<string>('');
  const { state } = useLocation<windowLocation>();
  const dispatch = useDispatch();

  const locationTestType = state?.testType;
  const locationInputType = state?.inputType;

  const history = useHistory();

  const { flowRoutes, flowIndex } = useSelector((state: stateType) => ({
    flowRoutes: state.appFlow.routes,
    flowIndex: state.appFlow.flowIndex,
  }));

  const isCancelHidden = decodedData.testType && decodedData.distance && decodedData.ratio ? true : false;

  useLayoutEffect(() => {
    setCustomInputType(locationInputType ? locationInputType : 'doctorTest');
  }, []);

  // redirect to test type page if user tried to get to input type page without test type
  useEffect(() => {
    const redirect = () => {
      history.push('/test_type');
    };

    !locationTestType && redirect();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setCustomInputType(value);
  };

  const handleSubmit = () => {
    if (isIntegration) {
      dispatch(appFlow.setFlowIndex(flowIndex + 1));
    }

    history.push({
      pathname: handleConnectRoute(customInputType),
      state: { testType: locationTestType, inputType: customInputType },
    });
  };

  const handleCancel = () => {
    let route;

    if (isIntegration) {
      dispatch(appFlow.setFlowIndex(flowIndex - 1));
      route = flowRoutes[flowIndex - 1];
    } else {
      route = '/test_type';
    }

    history.push({
      pathname: route,
      state: {
        testType: isIntegration && !decodedData.testType ? locationTestType : '',
        inputType: isIntegration && !decodedData.testType ? customInputType : '',
      },
    });
  };

  return (
    <AppTemplate
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      cancelHidden={isCancelHidden}
      heading=""
      description={strings.inputTypePage.description}
      className=" small-content"
      decodedData={decodedData}
      isIntegration={isIntegration}
    >
      <RadioGroup
        options={mapping.buildInputTypes(strings.inputTypePage.options)}
        checked={customInputType}
        handleChange={handleChange}
      />
    </AppTemplate>
  );
};

export default InputTypePage;
