import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// types
import { decodedTokenInfoType, stateType } from '../../types/types';
// routing
import routes from '../../config/routes';
import { withAuth } from '../../hocs/withAuth';

export interface PatientLayoutProps {
  component: React.FunctionComponent<any>;
  path: string | Array<string>;
  exact: boolean;
  location?: { state: { roomId: string; testType: string; inputType: string }; pathname: string };
  computedMatch?: { params: { language: string; token: string } };
  isIntegration: boolean;
  decodedData: decodedTokenInfoType;
}

const PatientLayout: FC<PatientLayoutProps> = ({ component: Component, ...args }: PatientLayoutProps) => {
  const history = useHistory();

  // redirect condition if ratio is in integration token
  const isRedirect = args.decodedData.ratio && args.path[1] === '/calibration' && args.computedMatch?.params.token;

  // store
  const userType = useSelector((state: stateType) => state.user.user.userType);
  const calibration = useSelector((state: stateType) => state.app.app.ratio);
  const distance = useSelector((state: stateType) => state.app.app.distance);

  // make these methods more dynamic by accessing routes with [] for more userTypes
  const otherUserRedirect = () => {
    setTimeout(() => {
      history.push(userType === 'doctor' ? routes.doctor.safeRoute : routes.patientRemote.safeRoute);
    }, 0);
  };

  const safeRedirect = () => {
    setTimeout(() => {
      history.push(routes.patient.safeRoute);
    }, 0);
  };

  const defaultRedirect = () => {
    setTimeout(() => {
      history.push(routes.patient.defaultRoute);
    }, 0);
  };

  const createRedirectObject = (
    decodedData: decodedTokenInfoType,
    pathname: string,
  ): { pathname: string; state: { testType: string; inputType: string } } => {
    return {
      pathname,
      state: {
        testType: decodedData.testType,
        inputType: decodedData.inputType,
      },
    };
  };

  const integrationRedirect = (decodedData: decodedTokenInfoType) => {
    const redirect = (pathname: string) => {
      setTimeout(() => {
        const historyObj = createRedirectObject(args.decodedData, pathname);

        history.push(historyObj);
      }, 0);
    };

    if (!decodedData.distance) {
      redirect('/distance');
    } else if (!decodedData.testType) {
      redirect('/test_type');
    } else if (!decodedData.inputType) {
      redirect('/input_type');
    } else {
      if (decodedData.inputType === 'selfTest') {
        redirect('/qr_connect');
      }
      if (decodedData.inputType === 'doctorTest') {
        redirect('/connect');
      }
    }
  };

  // user type check
  if (userType !== 'patient') {
    return <>{otherUserRedirect()}</>;
    // if user doesn't have calibration or distance saved
  } else if (args.isIntegration && isRedirect) {
    return <>{integrationRedirect(args.decodedData)}</>;
  } else if (!calibration && !distance && args.path[1] !== '/calibration') {
    return <>{safeRedirect()}</>;
    // if user doesn't have distance saved
  } else if (!distance && args.path[1] !== '/calibration' && args.path[1] !== '/distance') {
    return <>{defaultRedirect()}</>;
  }
  return <Component {...args} />;
};

export default withAuth<PatientLayoutProps>(PatientLayout);
