import React, { FC } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
// types
import { dyopsType } from '../../../types/dyops';
// style
import './index.scss';
// localization
import strings from '../../../localization';

interface DyopsProps {
  diametr: number;
  label?: boolean;
  active?: string;
  rotation?: string;
  dyops?: dyopsType;
  isUserConnected?: boolean;
}

const Dyops: FC<DyopsProps> = ({ diametr, label, active, rotation, dyops, isUserConnected }: DyopsProps) => {
  // build array with number of circle sectors and colors
  const buildDyopData = () => {
    const data = [];
    for (let i = 0; i < 16; i++) {
      data.push({ value: 6.25, color: i % 2 === 0 ? '#fff' : '#000' });
    }
    return data;
  };

  return (
    <div className="dyops__container">
      <div className="dyops__item-wrapper">
        <div className="dyops__item" style={{ minWidth: `${diametr * 1.5}px`, minHeight: `${diametr * 1.5}px` }}>
          <div
            className={`dyops__image-wrapper${active === 'left' ? ' rotating' : ''}${
              active === 'left' && rotation ? '-' + rotation : ''
            }`}
            style={{ minWidth: `${diametr}px`, minHeight: `${diametr}px` }}
          >
            <PieChart className="dyops__image" lineWidth={20} data={buildDyopData()} />
          </div>
        </div>
        {label && (
          <div className="dyops-indicator__container">
            <span
              className={`dyops-indicator__text${
                !dyops?.pause && isUserConnected && dyops?.active === 'left' ? ' active' : ''
              }`}
            >
              {strings.testPage.left}
            </span>
            {dyops?.isStarted && !dyops.pause && dyops?.active === 'left' && (
              <div
                className={`icon-direction${
                  !dyops.pause && isUserConnected ? (dyops.rotation ? ' clockwise' : ' counterclockwise') : ''
                }`}
              />
            )}
          </div>
        )}
      </div>
      <div
        className="dyops__item-wrapper"
        style={{ marginLeft: `${label ? (window.innerWidth <= 1100 ? 65 : 120) : diametr / 2.04}px` }}
      >
        <div
          className="dyops__item"
          style={{
            minWidth: `${diametr * 1.5}px`,
            minHeight: `${diametr * 1.5}px`,
          }}
        >
          <div
            className={`dyops__image-wrapper${active === 'right' ? ' rotating' : ''}${
              active === 'right' && rotation ? '-' + rotation : ''
            }`}
            style={{ minWidth: `${diametr}px`, minHeight: `${diametr}px` }}
          >
            <PieChart className="dyops__image" lineWidth={20} data={buildDyopData()} />
          </div>
        </div>
        {label && (
          <div className="dyops-indicator__container">
            <span
              className={`dyops-indicator__text${
                !dyops?.pause && isUserConnected && dyops?.active === 'right' ? ' active' : ''
              }`}
            >
              {strings.testPage.right}
            </span>
            {dyops?.isStarted && !dyops.pause && dyops?.active === 'right' && (
              <div
                className={`icon-direction${
                  !dyops.pause && isUserConnected ? (dyops.rotation ? ' clockwise' : ' counterclockwise') : ''
                }`}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dyops;
