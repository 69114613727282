import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import appFlow from '../../../redux/actions/appFlow';
import { stateType } from '../../../types/types';
import { EndTest } from '../../organisms/endTest';
import AppTemplate from '../../templates/appTemplate';

export const EndTestCmp: FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { routes } = useSelector((state: stateType) => state.appFlow);

  const handleClick = useCallback(() => {
    history.push(routes[0]);
    dispatch(appFlow.setFlowIndex(0));
  }, []);

  return (
    <AppTemplate
      //@ts-ignore
      cancelHidden
      handleSubmit={handleClick}
      //@ts-ignore
      submitHidden={!location?.state?.isPatient}
      submitLabel="NEXT STEP"
      cancelLabel="BACK"
      heading=""
      description=""
      isIntegration
    >
      <EndTest
        //@ts-ignore
        isDevice={!location?.state?.isPatient}
      />
    </AppTemplate>
  );
};
