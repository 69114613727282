import { takeEvery } from 'redux-saga/effects';
// sagas
import {
  sagaAppLogin,
  sagaAppLogout,
  sagaAppRegistration,
  sagaGetAppData,
  sagaEditAppData,
  sagaAppRefreshToken,
} from './app';
import { sagaCheckRoomId, sagaGetDyopsData } from './dyops';
import { sagaGetPatient } from './patient';
import { sagaGetPatients } from './patients';
import { sagaGetResult, sagaEditResult } from './result';
import { sagaGetResults } from './results';
import { sagaGetUser } from './user';

export function* sagaWatcher(): Generator {
  yield takeEvery('APP_LOGIN', sagaAppLogin);
  yield takeEvery('APP_LOGOUT', sagaAppLogout);
  yield takeEvery('APP_REGISTRATION', sagaAppRegistration);
  yield takeEvery('GET_APP_DATA', sagaGetAppData);
  yield takeEvery('EDIT_APP_DATA', sagaEditAppData);
  yield takeEvery('APP_REFRESH_TOKEN', sagaAppRefreshToken);
  yield takeEvery('GET_USER', sagaGetUser);
  yield takeEvery('GET_DYOPS', sagaGetDyopsData);
  yield takeEvery('CHECK_ROOM', sagaCheckRoomId);
  yield takeEvery('GET_RESULT', sagaGetResult);
  yield takeEvery('GET_RESULTS', sagaGetResults);
  yield takeEvery('EDIT_RESULT', sagaEditResult);
  yield takeEvery('GET_PATIENTS', sagaGetPatients);
  yield takeEvery('GET_PATIENT', sagaGetPatient);
}
