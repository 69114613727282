import React, { FC, useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// components
import Input from '../../../atoms/input';
// actions
import appAction from '../../../../redux/actions/app';
// types
import { authParamsType, stateType } from '../../../../types/types';

interface FormLoginProps {
  stateValues?: authParamsType;
}

const FormLogin: FC<FormLoginProps> = ({ stateValues }: FormLoginProps) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
  });

  const history = useHistory();
  const dispatch = useDispatch();

  // store
  const appLoading = useSelector((state: stateType) => state.app.loading);

  // set input values after success registration
  useEffect(() => {
    stateValues && setValues({ ...values, email: stateValues.email, password: stateValues.password });
  }, [stateValues]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const doctorCallback = () => {
    history.push('/doctor_home');
  };

  const patientCallback = () => {
    history.push('/distance');
  };

  const handleQr = () => {
    history.push('/qr_scan');
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    );

    if (!pattern.test(values.email)) {
      alert('Please, enter proper email');
    } else {
      dispatch(appAction.login(values, patientCallback, doctorCallback));
    }
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__heading">
          <h3 className="page-heading small">Login</h3>
          <span className="page-description light">
            A Dyop is a spinning segmented visual target where the ability to detect the direction of spinning of the
            smallest diameter (arc width) Dyop is an indicator (benchmark) of visual clarity/acuity.
          </span>
        </div>
        <div className="form__row">
          <div className="form__column">
            <Input
              label="Email"
              placeholder="Email"
              input={values.email}
              id="email"
              name="email"
              disabled={appLoading.status}
              handleChange={handleInput}
              inputType="email"
              icon="email"
              required={true}
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__column">
            <Input
              label="Password"
              placeholder="Password"
              input={values.password}
              id="password"
              name="password"
              disabled={appLoading.status}
              handleChange={handleInput}
              icon="password"
              inputType="password"
              required={true}
              min={6}
            />
          </div>
          <Link className="form__forgot-link" to="">
            Forgot password?
          </Link>
        </div>
        <div className="form__btns form__btns_login">
          <div>
            <span>Don`t have an account?</span>
            <Link to="/registration">Register</Link>
          </div>
          <div className="form__btns-item">
            <button type="button" className="btn btn_default btn_wide" disabled={appLoading.status} onClick={handleQr}>
              Scan QR code
            </button>
            <button type="submit" className="btn btn_submit btn_wide" disabled={appLoading.status}>
              Login
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default FormLogin;
