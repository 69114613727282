import React, { FC } from 'react';
import { Route, useHistory } from 'react-router-dom';
// api
import { apiApp } from '../../api/app';

export interface LoginLayoutProps {
  component: React.FunctionComponent<any>;
  path: string | Array<string>;
  exact: boolean;
}

const LoginLayout: FC<LoginLayoutProps> = ({ component: Component, ...args }: LoginLayoutProps) => {
  const history = useHistory();

  // accessToken check expiration
  const accessTokenCheck = apiApp.decodeToken(localStorage.getItem('accessToken'), 'date');

  const redirect = () => {
    setTimeout(() => {
      history.push('/');
    }, 0);
  };

  if (!accessTokenCheck) {
    return (
      <Route
        path={args.path}
        render={() => {
          return <Component {...args} />;
        }}
      />
    );
  } else {
    return <>{redirect()}</>;
  }
};

export default LoginLayout;
