import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//components
import AppTemplate from '../../templates/appTemplate';
import Distance from '../../organisms/distance';
// actions
import appAction from '../../../redux/actions/app';
// types
import { appTheme, decodedTokenInfoType, stateType } from '../../../types/types';
// localization
import strings from '../../../localization';
import appFlow from '../../../redux/actions/appFlow';
import DistanceNew from '../../organisms/distance/distanceNew';
import { useTheme } from '../../../hooks/theme';
import './index.scss';

interface DistancePageProps {
  isIntegration: boolean;
  decodedData: decodedTokenInfoType;
}

const renderTitleAndDescriptionByTheme = (
  theme: appTheme,
  distance: string,
  isTapeMeasure: boolean,
  footSteps: number,
) => {
  switch (theme) {
    case 'dark':
      return {
        title: `Choose how to measure distance from the screen.`,
        description: isTapeMeasure
          ? `Choose "I will measure with a tape measure" if you will self-measure ${distance} meters from the monitor. Or, choose "I will measure by taking steps" and choose your shoe size if you want to measure by taking steps.`
          : footSteps
          ? `Self measure ${footSteps} steps from the monitor.`
          : 'Choose your shoe size ',
      };
    default:
      return {
        title: '',
        description: strings.distancePage.description,
      };
  }
};

const DistancePage: FC<DistancePageProps> = ({ isIntegration, decodedData }: DistancePageProps) => {
  const [distance, setDistance] = useState<string>('');
  const [footSizeUnit, setFootSizeUnit] = useState<string>('');
  const [customSize, setCustomSize] = useState<string>('');
  const [footSteps, setFootSteps] = useState<number>(0);
  const [isScroll, setIsScroll] = useState<boolean>(false);
  const [isTapeMeasure, setIsTapeMeasure] = useState(true);
  const [initialDistance, setInitialDistance] = useState('');
  // distance prev state for onBlur listener
  const [tempDistance, setTempDistance] = useState<string>('');

  const theme = useSelector((state: stateType) => state.app.app.theme);
  const { isDark } = useTheme();

  const { title, description } = useMemo(
    () => renderTitleAndDescriptionByTheme(theme, initialDistance, isTapeMeasure, footSteps),
    [theme, distance, footSteps, isTapeMeasure],
  );

  const DistanceComponentToRender = isDark ? DistanceNew : Distance;

  const disabled = useMemo(() => {
    if (isDark) {
      return !footSteps && !isTapeMeasure;
    } else {
      return distance === tempDistance ? distance.length === 0 : tempDistance.length === 0;
    }
  }, [isDark, distance, tempDistance, footSteps, isTapeMeasure]);

  const dispatch = useDispatch();
  const history = useHistory();

  const isCancelHidden = decodedData.ratio > 0 && !isDark;

  const { appData, flowRoutes, flowIndex } = useSelector((state: stateType) => ({
    appData: state.app.app,
    flowRoutes: state.appFlow.routes,
    flowIndex: state.appFlow.flowIndex,
  }));

  useLayoutEffect(() => {
    setInitialDistance(appData.distance);
  });

  const createSubmitObject = (
    decodedData: decodedTokenInfoType,
  ): { pathname: string; state?: { testType?: string; inputType?: string } } => {
    if (isIntegration) {
      dispatch(appFlow.setFlowIndex(flowIndex + 1));
      return {
        pathname: flowRoutes[flowIndex + 1],
        state: {
          testType: decodedData.testType,
          inputType: decodedData.inputType,
        },
      };
    }

    return { pathname: '/test_type' };
  };

  const handleSubmit = () => {
    const historyObj = createSubmitObject(decodedData);

    const callback = () => {
      history.push(historyObj);
    };

    const distanceToSet = distance;

    const data = { ...appData, distance: distanceToSet, footSteps, customSize, footSizeUnit };
    dispatch(appAction.editAppDataAction(data, isIntegration, callback));
  };

  const handleCancel = () => {
    if (isDark) {
      dispatch(appFlow.setFlowIndex(flowIndex - 1));
      history.push(flowRoutes[flowIndex - 1]);
    } else {
      dispatch(appFlow.setFlowIndex(flowIndex - 1));
      history.push('/calibration');
    }
  };

  return (
    <AppTemplate
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      disabled={disabled}
      heading={title}
      description={description}
      className=" small-content"
      decodedData={decodedData}
      isIntegration={isIntegration}
      cancelHidden={isCancelHidden}
    >
      <DistanceComponentToRender
        appData={appData}
        distance={distance}
        footSizeUnit={footSizeUnit}
        customSize={customSize}
        footSteps={footSteps}
        setIsTapeMeasure={setIsTapeMeasure}
        setDistance={setDistance}
        setFootSizeUnit={setFootSizeUnit}
        setCustomSize={setCustomSize}
        setFootSteps={setFootSteps}
        isScroll={isScroll}
        setIsScroll={setIsScroll}
        tempDistance={tempDistance}
        setTempDistance={setTempDistance}
      />
    </AppTemplate>
  );
};

export default DistancePage;
