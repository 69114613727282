import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import Notification from '../../atoms/notification';
import Footer from '../../organisms/footer';
// actions
import appAction from '../../../redux/actions/app';
// types
import { stateType, userType } from '../../../types/types';
// helpers
import { useWindowZoom } from '../../../tools';
// styles
import './index.scss';

interface TestTemplateProps {
  children: React.ReactNode;
  height: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  disabled?: boolean;
  submitHidden?: boolean;
  submitLabel?: string;
  isConnectStatusShown?: boolean;
  isUserConnected?: boolean;
  customUserType?: userType;
  isSubmitDecline?: boolean;
  className?: string;
}

const TestTemplate: FC<TestTemplateProps> = ({
  children,
  submitHidden,
  handleSubmit,
  handleCancel,
  disabled,
  submitLabel,
  isConnectStatusShown,
  isUserConnected,
  customUserType,
  isSubmitDecline,
  height,
  className,
}: TestTemplateProps) => {
  const dispatch = useDispatch();
  const windowZoom = useWindowZoom();
  const path = window.location.pathname;

  //store
  const userType = useSelector((state: stateType) => state.user.user.userType);
  const calibration = useSelector((state: stateType) => state.app.app.ratio);

  // iOS zoom disabling
  useEffect(() => {
    const pinchZoom = (event: any) => {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    };

    let lastTouchEnd = 0;

    const doubleTapZoom = (event: any) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    };

    const preventDrag = (e: any) => {
      e.preventDefault();
    };

    document.addEventListener('touchmove', pinchZoom, { passive: false });
    document.addEventListener('touchmove', preventDrag, { passive: false });
    document.addEventListener('touchend', doubleTapZoom, { passive: false });

    return () => {
      document.removeEventListener('touchmove', pinchZoom);
      document.removeEventListener('touchmove', preventDrag);
      document.removeEventListener('touchend', doubleTapZoom);
    };
  }, []);

  useEffect(() => {
    const zoomReaction =
      userType === 'patient' &&
      ((path === '/' && calibration) ||
        path === '/distance' ||
        path === '/test' ||
        (path === '/test_type' && calibration) ||
        path === '/connect');

    if (zoomReaction) {
      dispatch(appAction.appNotification(windowZoom));
    }

    return () => {
      dispatch(appAction.appNotification(false));
    };
  }, [windowZoom]);

  return (
    <>
      <Notification />
      <div className={`content content_test${className ? className : ''}`}>
        <div className="content__inner">{children}</div>
        <Footer
          submitHidden={submitHidden}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          disabled={disabled}
          submitLabel={submitLabel}
          isConnectStatusShown={isConnectStatusShown}
          isUserConnected={isUserConnected}
          userType={customUserType}
          isSubmitDecline={isSubmitDecline}
          height={height}
        />
      </div>
    </>
  );
};

export default TestTemplate;
