import React, { FC, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import Notification from '../../atoms/notification';
import Header from '../../organisms/header';
import Footer from '../../organisms/footer';
// actions
import appAction from '../../../redux/actions/app';
// types
import { decodedTokenInfoType, stateType, userType } from '../../../types/types';
// helpers
import { useWindowZoom } from '../../../tools';
// styles
import './index.scss';
import HeaderDark from '../../organisms/header/headerDark';
import { useTheme } from '../../../hooks/theme';

interface AppTemplateProps {
  children: React.ReactNode;
  heading: string;
  description: string;
  isHeaderHidden?: boolean;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  renderDescription?: () => JSX.Element | undefined;
  submitHidden?: boolean;
  cancelHidden?: boolean;
  disabled?: boolean;
  submitLabel?: string;
  cancelLabel?: string;
  isCancelDecline?: boolean;
  path?: string;
  isConnectStatusShown?: boolean;
  height?: boolean;
  isUserConnected?: boolean;
  customUserType?: userType;
  connectionLabelHidden?: boolean;
  className?: string;
  decodedData?: decodedTokenInfoType;
  isIntegration?: boolean;
  isDevice?: boolean;
}

const AppTemplate: FC<AppTemplateProps> = ({
  children,
  handleSubmit,
  handleCancel,
  submitHidden,
  cancelHidden,
  disabled,
  heading,
  description,
  isHeaderHidden,
  submitLabel,
  cancelLabel,
  isCancelDecline,
  path,
  height,
  isConnectStatusShown,
  isUserConnected,
  customUserType,
  connectionLabelHidden,
  className,
  decodedData,
  isDevice,
  isIntegration,
  renderDescription,
}: AppTemplateProps) => {
  const dispatch = useDispatch();
  const { isDark } = useTheme();
  const windowZoom = useWindowZoom();
  const refTitle = useRef() as React.MutableRefObject<HTMLHeadingElement>;
  const locationPath = window.location.pathname;

  //store
  const userType = useSelector((state: stateType) => state.user.user.userType);
  const calibration = useSelector((state: stateType) => state.app.app.ratio);

  const HeaderToRender = isDark ? HeaderDark : Header;

  const isNavigationHidden =
    userType !== 'patient' ||
    path === '/result/:id' ||
    locationPath.includes('/self_test') ||
    locationPath.includes('/test');

  // iOS zoom disabling
  useEffect(() => {
    const pinchZoom = (e: any) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    let lastTouchEnd = 0;

    const doubleTapZoom = (e: any) => {
      const now = new Date().getTime();
      if (now - lastTouchEnd <= 300) {
        e.preventDefault();
      }
      lastTouchEnd = now;
    };

    document.addEventListener('touchmove', pinchZoom, { passive: false });
    document.addEventListener('touchend', doubleTapZoom, { passive: false });

    return () => {
      document.removeEventListener('touchmove', pinchZoom);
      document.removeEventListener('touchend', doubleTapZoom);
    };
  }, []);

  useEffect(() => {
    refTitle.current?.focus();
  }, []);

  useEffect(() => {
    const zoomReaction =
      userType === 'patient' &&
      ((locationPath === '/' && calibration) ||
        locationPath === '/distance' ||
        locationPath === '/test' ||
        (locationPath === '/test_type' && calibration) ||
        locationPath === '/connect');

    if (zoomReaction) {
      dispatch(appAction.appNotification(windowZoom));
    }

    return () => {
      dispatch(appAction.appNotification(false));
    };
  }, [windowZoom]);

  return (
    <>
      <Notification />
      <div className={`content${className ? className : ''}`}>
        {!isHeaderHidden && (
          <HeaderToRender
            userType={customUserType}
            path={locationPath}
            navigationHidden={isNavigationHidden}
            isIntegration={!!isIntegration}
            isDevice={!!isDevice}
            isUserConnected={isUserConnected}
            decodedData={decodedData}
          />
        )}
        <div className="content__inner">
          {(heading || description) && (
            <div className="content__heading-container">
              {heading && (
                <h3 tabIndex={0} ref={refTitle} className="page-heading">
                  {heading}
                </h3>
              )}
              {renderDescription ? (
                renderDescription()
              ) : (
                <div tabIndex={description.length < 1 ? -1 : 0}>
                  <span className="page-description">{description}</span>
                </div>
              )}
            </div>
          )}
          {children}
        </div>
        <Footer
          handleSubmit={handleSubmit}
          submitHidden={submitHidden}
          cancelHidden={cancelHidden}
          handleCancel={handleCancel}
          disabled={disabled}
          height={height}
          isDevice={isDevice}
          submitLabel={submitLabel}
          cancelLabel={cancelLabel}
          isCancelDecline={isCancelDecline}
          isConnectStatusShown={isConnectStatusShown}
          isUserConnected={isUserConnected}
          userType={customUserType}
          connectionLabelHidden={connectionLabelHidden}
        />
      </div>
    </>
  );
};

export default AppTemplate;
