import { call, delay, put } from 'redux-saga/effects';
// api
import { apiApp } from '../../api/app';
import { apiResult } from '../../api/result';
// actions
import appAction from '../actions/app';
import resultAction from '../actions/result';
// types
import { editResultActionReturnType, getResultActionReturnType } from '../../types/actions/result';
//mapping
import { mapping } from '../../config/mapping';

export function* sagaGetResult(action: getResultActionReturnType): any {
  try {
    yield put(appAction.appLoading(true, 0));
    const resultResponse = yield call(apiApp.getRoomData, action.roomId);
    yield put(appAction.appLoading(true, 90));
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    yield put(resultAction.getResult(resultResponse.data.testResult));
    yield delay(10);
    yield put(appAction.appLoading(false, 0));
  } catch (e) {
    console.log(e);
    yield put(appAction.appLoading(true, 100));
    yield delay(1000);
    yield put(appAction.appLoading(false, 0));
  }
}

export function* sagaEditResult(action: editResultActionReturnType): any {
  try {
    yield put(appAction.appLoading(true, 0));
    // get distance, unit measure and patient name from sockets before editing results
    const resultResponse = yield call(apiApp.getRoomData, action.roomId);
    const resultInfo = {
      ...action.dyops.result,
      ratio: action.isIntegration ? resultResponse.data.patientData.ratio : '',
      distance: resultResponse.data.patientData.distance,
      patientName: action.isIntegration
        ? ''
        : `${resultResponse.data.patientData?.user?.firstName} ${resultResponse.data?.patientData.user?.lastName}`,
      patientId: action.isIntegration ? '' : resultResponse?.data?.patientData?.user?.userId,
      doctorName: action.isIntegration ? '' : action.dyops.result.doctorName,
      unit: resultResponse.data.patientData.unit,
      inputType: resultResponse.data.patientData.inputType,
    };
    const result = mapping.buildPatientResultInfo(resultInfo);
    if (!action.isIntegration) {
      yield call(apiResult.editResult, action.roomId, result);
    }
    yield put(appAction.appLoading(true, 90));
    yield delay(10);
    action.patientCallback(action.isIntegration, result);
    yield put(appAction.appLoading(true, 100));
    yield delay(10);
    action.doctorCallback(action.isIntegration, result);
    yield put(appAction.appLoading(false, 0));
  } catch (e) {
    console.log(e);
  }
}
