import React, { FC, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import NoSleep from 'nosleep.js';
import useSound from 'use-sound';
import { useDispatch, useSelector } from 'react-redux';
// components
import Dyops from '../../atoms/dyops';
import EyePreparation from '../../atoms/eye';
import EyePreparationDark from '../../atoms/eye/eyeDark';
// actions
import dyopsAction from '../../../redux/actions/dyops';
// types
import { loadingType, roomDataType, stateType } from '../../../types/types';
import { dyopsType } from '../../../types/dyops';
// style
import './index.scss';
// helpers
import { getDyopsSize } from '../../../tools';
// sound
import sfxBeep from '../../../assets/sfx/BellSound.wav';
// websockets
import { webSocketConnect } from '../../../websocket/websocket';
// mapping
import { mapping } from '../../../config/mapping';
import { useTheme } from '../../../hooks/theme';

interface PatientTestProps {
  appLoading: loadingType;
  isConnected: boolean;
  setIsConnected: (isConnected: boolean) => void;
  setIsUserConnected: (isUserConnected: boolean) => void;
  height: boolean;
  state: { roomId: string; inputType: string };
  dyops: dyopsType;
  isIntegration: boolean;
}

const PatientTest: FC<PatientTestProps> = ({
  appLoading,
  isConnected,
  setIsConnected,
  setIsUserConnected,
  height,
  state,
  dyops,
  isIntegration,
}: PatientTestProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [play] = useSound(sfxBeep);
  const roomId = state.roomId;
  const locationInputType = state.inputType;
  const socketRef = useRef<any>();
  const noSleep = new NoSleep();
  const { isDark } = useTheme();

  const PreparationComponent = useMemo(() => {
    return isDark ? EyePreparationDark : EyePreparation;
  }, [isDark]);

  // store
  const appData = useSelector((state: stateType) => state.app.app);
  const user = useSelector((state: stateType) => state.user.user);

  // websockets
  const accessToken =
    isIntegration && sessionStorage.getItem('integrationToken')
      ? sessionStorage.getItem('integrationToken')
      : localStorage.getItem('accessToken')
      ? localStorage.getItem('accessToken')
      : '';

  const socketUrl = process.env.REACT_APP_API_SOCKET_URL || '';

  useEffect(() => {
    noSleep.enable();

    socketRef.current = webSocketConnect(socketUrl, roomId, accessToken ? accessToken : '');

    const { current: socket } = socketRef;

    socket.on('connect', () => {
      setIsConnected(true);
      // send patient info
      const patientInfo = {
        ratio: appData.ratio,
        distance: appData.distance,
        unit: appData.unit,
        footSteps: appData.footSteps,
        inputType: locationInputType,
        user,
      };
      const payload = mapping.buildPatientInfo(patientInfo, isIntegration);
      socket.emit('emitUpdatePatient', payload);
      dispatch(dyopsAction.getDyopsAction(socket.io.opts.query.roomId));
    });

    socket.on('onUpdateDoctor', (data: { dyops: dyopsType }) => {
      dispatch(dyopsAction.getDyops(data.dyops));
    });

    socket.on('roomStatus', ({ room }: roomDataType) => {
      if (locationInputType === 'selfTest') {
        const patient = room.users.find((item) => item.type !== 'doctor');
        setIsUserConnected(patient && patient.connections > 1 ? true : false);
      }
      if (locationInputType !== 'selfTest') {
        setIsUserConnected(room.users.find((item) => item.type === 'doctor') ? true : false);
      }
    });

    socket.on('disconnect', () => {
      console.log('disconnected from ' + socket.io.opts.query.roomId);
      dispatch(dyopsAction.clearDyops());
      setIsConnected(false);
    });

    return () => {
      noSleep.disable();
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (dyops.isFinished) {
      if (isIntegration) {
        const defaultNavigation = locationInputType === 'selfTest' ? '/qr_connect' : `/connect`;
        const darkNavigation = locationInputType === 'selfTest' ? '/end_test' : `/connect`;
        history.push({
          pathname: isDark ? darkNavigation : defaultNavigation,
          state: {
            ...state,
            isPatient: true,
          },
        });
        window?.top?.postMessage(JSON.stringify(dyops.result), '*');
      } else {
        history.push({ pathname: `/result/${roomId}`, state });
      }
    }
  }, [dyops.isFinished, isIntegration, isDark]);

  useEffect(() => {
    if (dyops.isStarted && !dyops.pause) {
      play();
    }

    // dyops size debug for test and local servers
    (process.env.NODE_ENV === 'development' || window.location.origin === 'https://dyoptest-self.com') &&
      console.log('current size:', dyops.size);
  }, [dyops.steps]);

  return (
    <div
      className={`patient-test${height ? ' rotate' : ''}`}
      style={height ? { width: `${window.innerHeight}px` } : {}}
    >
      {!appLoading.status && isConnected && !dyops.isStarted ? (
        <PreparationComponent
          eye={dyops.closeLeftEye ? 'left' : 'right'}
          type={locationInputType}
          distance={appData.distance}
          unit={appData.unit}
          footSteps={appData.footSteps}
        />
      ) : (
        dyops.isStarted && (
          <Dyops
            diametr={appData.ratio * getDyopsSize(dyops.size, appData.distance, appData.unit)}
            active={dyops.pause ? 'none' : dyops.active}
            rotation={dyops.rotation ? '' : 'counter-clockwise'}
          />
        )
      )}
    </div>
  );
};

export default PatientTest;
