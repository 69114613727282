import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
// types
import { stateType } from '../../types/types';
// routing
import routes from '../../config/routes';
import { withAuth } from '../../hocs/withAuth';

interface DoctorLayoutProps {
  component: React.FunctionComponent<any>;
  path: string | Array<string>;
  exact: boolean;
  location?: { state: { roomId: string } };
  isIntegration: boolean;
}

const DoctorLayout: FC<DoctorLayoutProps> = ({ component: Component, ...args }: DoctorLayoutProps) => {
  const history = useHistory();

  // store
  const userType = useSelector((state: stateType) => state.user.user.userType);

  // make these method more dynamic by accessing routes with [] for more userTypes
  const otherUserRedirect = () => {
    setTimeout(() => {
      history.push(userType === 'patient' ? routes.patient.safeRoute : routes.patientRemote.safeRoute);
    }, 0);
  };

  const integrationRedirect = () => {
    setTimeout(() => {
      history.push('/connect');
    }, 0);
  };

  if (userType !== 'doctor') {
    return <>{otherUserRedirect()}</>;
  } else if (args.isIntegration && args.path === '/doctor_home') {
    return <>{integrationRedirect()}</>;
  }
  return <Component {...args} />;
};

export default withAuth(DoctorLayout);
