import { call, delay, put } from 'redux-saga/effects';
// api
import { apiApp } from '../../api/app';
// actions
import appAction from '../actions/app';
import dyopsAction from '../actions/dyops';
// types
import { checkRoomActionReturnType, getDyopsActionReturnType } from '../../types/actions/dyops';

// could be check for doctor presence in room to connect only if doctor is in the room later
export function* sagaCheckRoomId(action: checkRoomActionReturnType): any {
  try {
    yield put(appAction.appLoading(true, 0));
    yield call(apiApp.getRoomData, action.roomId);
    yield put(appAction.appLoading(true, 90));
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    yield put(appAction.appLoading(false, 0));
    action.callback();
  } catch (e) {
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    alert('Invalid code');
    yield put(appAction.appLoading(false, 0));
  }
}

export function* sagaGetDyopsData(action: getDyopsActionReturnType): any {
  try {
    yield put(appAction.appLoading(true, 0));
    const dyopsDataResponse = yield call(apiApp.getRoomData, action.roomId);
    yield put(appAction.appLoading(true, 90));
    yield put(appAction.appLoading(true, 100));
    yield delay(500);
    yield put(appAction.appLoading(false, 0));
    if (dyopsDataResponse.data.doctorData.dyops) {
      yield put(dyopsAction.getDyops(dyopsDataResponse.data.doctorData.dyops));
    } else {
      // selfTest start
      action.callback && action.dyops && action.callback(action.dyops);
    }
    // selfTest patient data recieving
    if (action.callback && action.dyops && dyopsDataResponse.data.patientData) {
      // if user tries to get to selfTest control page and test is already started
      if (dyopsDataResponse.data.doctorData.dyops) {
        yield put(
          dyopsAction.getDyops({
            ...dyopsDataResponse.data.doctorData.dyops,
            result: {
              ...dyopsDataResponse.data.doctorData.dyops.result,
              distance: dyopsDataResponse.data.patientData.distance,
              unit: dyopsDataResponse.data.patientData.unit,
              footSteps: dyopsDataResponse.data.patientData.footSteps
                ? dyopsDataResponse.data.patientData.footSteps
                : 0,
            },
          }),
        );
      }
      // start/restart selfTest with patient data recieving
      else {
        action.callback({
          ...action.dyops,
          result: {
            ...action.dyops.result,
            distance: dyopsDataResponse.data.patientData.distance,
            unit: dyopsDataResponse.data.patientData.unit,
            footSteps: dyopsDataResponse.data.patientData.footSteps ? dyopsDataResponse.data.patientData.footSteps : 0,
          },
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}
