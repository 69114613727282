import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// components
import AppTemplate from '../../templates/appTemplate';
import ResultContainer from '../../organisms/result';
// actions
import resultAction from '../../../redux/actions/result';
import resultsAction from '../../../redux/actions/results';
// types
import { stateType, windowLocation } from '../../../types/types';
import { useLocation } from 'react-router-dom';

interface ResultPageProps {
  computedMatch: { params: { id: string }; path: string };
  location: { state?: { testType: string; inputType: string } };
}

const ResultPage: FC<ResultPageProps> = ({ computedMatch }: ResultPageProps) => {
  const roomId = computedMatch.params.id;
  const path = computedMatch.path;
  const location = useLocation<windowLocation>();
  const dispatch = useDispatch();
  const history = useHistory();

  // store
  const userType = useSelector((state: stateType) => state.user.user.userType);
  const result = useSelector((state: stateType) => state.result.result);

  const isSubmitHidden =
    (userType === 'patientRemote' && result.inputType !== 'selfTest') ||
    (userType === 'doctor' && result.inputType !== 'doctorTest');

  useEffect(() => {
    dispatch(resultAction.getResultAction(roomId));

    return () => {
      dispatch(resultAction.clearResult());
      dispatch(resultsAction.getResultsClear());
    };
  }, []);

  useEffect(() => {
    const data = { first: 3, skip: 0, letter: '' };

    result.patientId && dispatch(resultsAction.getResultsAction(String(result.patientId), data));
  }, [result.patientId]);

  const handleSubmit = () => {
    history.push({
      pathname: `/${
        userType === 'doctor'
          ? 'd_test'
          : userType === 'patientRemote'
          ? `self_test/${sessionStorage.getItem('selfTestToken')}/${roomId}`
          : 'test'
      }`,
      //@ts-ignore
      state: { roomId, ...location.state },
    });
  };

  const handleCancel = () => {
    history.push(`/${userType === 'doctor' ? 'connect' : userType === 'patientRemote' ? 'login' : 'test_type'}`);
  };

  return (
    <AppTemplate
      heading=""
      description="Dyop Vision Test Results. The Dyop acuity endpoint (maximum visual clarity) is the smallest Dyop angular diameter where the direction of Dyop spinning can be determined."
      submitHidden={isSubmitHidden}
      handleSubmit={handleSubmit}
      submitLabel="Restart test"
      handleCancel={handleCancel}
      cancelLabel="Exit test"
      isCancelDecline={true}
      path={path}
      className=" small-content"
      isIntegration={false}
    >
      <ResultContainer
        roomId={roomId}
        result={result}
        patientId={result.patientId}
        patientName={result.patientName}
        userType={userType}
      />
    </AppTemplate>
  );
};

export default ResultPage;
