import React, { FC, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// components
import AppTemplate from '../../templates/appTemplate';
import Connect from '../../organisms/connect';
// types
import { decodedTokenInfoType, stateType, windowLocation } from '../../../types/types';
// localization
import strings from '../../../localization';
import appFlow from '../../../redux/actions/appFlow';

interface ConnectPageProps {
  location?: { state?: { testType: string; inputType: string } };
  isIntegration: boolean;
  decodedData: decodedTokenInfoType;
}

const ConnectPage: FC<ConnectPageProps> = ({ isIntegration, decodedData }: ConnectPageProps) => {
  // store

  const dispatch = useDispatch();
  const history = useHistory();

  const { state } = useLocation<windowLocation>();

  const locationTestType = state?.testType;
  const locationInputType = state?.inputType;

  const { flowRoutes, flowIndex, appLoading, userType } = useSelector((state: stateType) => ({
    appData: state.app.app,
    flowRoutes: state.appFlow.routes,
    flowIndex: state.appFlow.flowIndex,
    userType: state.user.user.userType,
    appLoading: state.app.loading,
  }));

  const isRedirect = (!locationTestType || !locationInputType) && userType !== 'doctor';

  const isCancelHidden =
    (userType === 'doctor' && isIntegration) ||
    (decodedData.testType && decodedData.distance && decodedData.ratio && decodedData.inputType)
      ? true
      : false;

  useEffect(() => {
    const redirect = () => {
      history.push('/input_type');
    };

    isRedirect && redirect();
  }, []);

  const handleCancel = () => {
    let route;
    if (userType === 'doctor') {
      route = '/doctor_home';
    }
    if (isIntegration) {
      dispatch(appFlow.setFlowIndex(flowIndex - 1));
      route = flowRoutes[flowIndex - 1];
    } else {
      route = '/input_type';
    }

    history.push({
      pathname: route,
      state: {
        testType:
          (isIntegration && !decodedData.inputType) || (isIntegration && !decodedData.testType) ? locationTestType : '',
        inputType:
          (isIntegration && !decodedData.inputType) || (isIntegration && !decodedData.testType)
            ? locationInputType
            : '',
      },
    });
  };

  return (
    <AppTemplate
      submitHidden={true}
      handleCancel={handleCancel}
      cancelHidden={isCancelHidden}
      heading={
        userType === 'doctor'
          ? strings.connectPage.heading.headingDoctorControl
          : strings.connectPage.heading.headingDoctorTest
      }
      description={
        userType === 'doctor'
          ? strings.connectPage.description.doctorControlDescription
          : strings.connectPage.description.doctorTestDescription
      }
      decodedData={decodedData}
      isIntegration={isIntegration}
    >
      <Connect userType={userType} state={state} appLoading={appLoading} />
    </AppTemplate>
  );
};

export default ConnectPage;
