import { shoeMetricsType } from '../types/types';

export const footSizes: Array<{
  key: shoeMetricsType;
  items: Array<number>;
}> = [
  { key: 'Europe', items: [35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45] },
  /* temporary disabled metrics */
  // { key: 'Mexico', items: [0, 0, 0, 4.5, 5.5, 6, 6.5, 7, 7.5, 9, 10] },
  // { key: 'Japan(M)', items: [21.5, 22.5, 23, 24, 25, 25.5, 26, 26.5, 27.5, 28.5, 29.5] },
  // { key: 'Japan(W)', items: [21, 22, 22.5, 23.5, 24.5, 25, 25.5, 26, 27, 28, 29] },
  { key: 'UK(M)', items: [3, 4, 4.5, 5.5, 6.5, 7, 7.5, 8, 8.5, 10, 11] },
  { key: 'UK(W)', items: [2.5, 3.5, 4, 5, 6, 6.5, 7, 7.5, 8, 9.5, 10.5] },
  // { key: 'Australia(M)', items: [3, 4, 4.5, 5.5, 6.5, 7, 7.5, 8, 8.5, 10, 11] },
  // { key: 'Australia(W)', items: [3.5, 4.5, 5, 6, 7, 7.5, 8, 8.5, 9, 10.5, 11.5] },
  { key: 'USA & Canada (Men)', items: [3.5, 4.5, 5, 6, 7, 7.5, 8, 8.5, 9, 10.5, 11.5] },
  { key: 'USA & Canada (Women)', items: [5, 6, 6.5, 7.5, 8.5, 9, 9.5, 10, 10.5, 12, 13] },
  /* temporary disabled metrics */
  // { key: 'Korea', items: [228, 235, 238, 245, 251, 254, 257, 260, 267, 273, 279] },
  { key: 'Centimeters', items: [22.8, 23.5, 23.8, 24.5, 25.1, 25.4, 25.7, 26, 26.7, 27.3, 27.9] },
];

// registration form
export const titles = [
  { key: 'mr', value: 'mr', label: 'Mr' },
  { key: 'ms', value: 'ms', label: 'Ms' },
  { key: 'mrs', value: 'mrs', label: 'Mrs' },
];

export const units = [
  { key: 'meter', value: 'meter', label: 'Meters' },
  { key: 'feet', value: 'feet', label: 'Feet' },
  { key: 'decimal', value: 'dec', label: 'Decimal' },
];

export const genders = [
  { key: 'male', value: 'male', label: 'Male' },
  { key: 'female', value: 'female', label: 'Female' },
];
