import React, { FC, useEffect, useState } from 'react';
// components
import CalibrationRuler from '../../moleculs/calibrationRuler';
import Radio from '../../atoms/radio';
// types
import { appDataType } from '../../../types/types';
// style
import './index.scss';
// helpers
import { useWindowSize } from '../../../tools';
// localization
import strings from '../../../localization';

interface CalibrationProps {
  appData: appDataType;
  unit: string;
  setRatio: (ratio: number) => void;
  setUnit: (unit: string) => void;
}

const Calibration: FC<CalibrationProps> = ({ appData, unit, setRatio, setUnit }: CalibrationProps) => {
  const [startWidth, setStartWidth] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const initialRulerWidth = window.innerWidth <= 1100 ? 204 : 455;
  const initialRulerAbsoluteWidth = window.innerWidth <= 1100 ? 53.98 : 86;

  const [windowWidth] = useWindowSize();

  // set initial values from localStorage or default ones
  useEffect(() => {
    if (!appData.ratio) {
      handleStartValues();
    } else {
      setUnit(appData.unit);
      setWidth(appData.ratio * initialRulerAbsoluteWidth);
      setRatio(appData.ratio);
    }
  }, [appData]);

  // handle ruler start width when window width dynamicaly changes
  useEffect(() => {
    handleStartWidth(window.innerWidth);
  }, [windowWidth]);

  // handle measurement units by radio buttons
  const handleUnits = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnit(e.target.value);
  };

  // handle ruler width with buttons
  const handleRulerWidth = (width: number) => {
    setWidth(width);
    setRatio(width / startWidth);
  };

  // handle values if localStorage is empty
  const handleStartValues = () => {
    setUnit('meter');
    setStartWidth(initialRulerAbsoluteWidth);
    setWidth(initialRulerWidth);
    setRatio(initialRulerWidth / initialRulerAbsoluteWidth);
  };

  // handle ruler start width method
  const handleStartWidth = (windowInnerWidth: number): void => {
    setStartWidth(windowInnerWidth <= 1100 ? 53.98 : 86);
  };

  // handle reset
  const handleReset = () => {
    setWidth(initialRulerWidth);
    setRatio(initialRulerWidth / startWidth);
  };

  return (
    <div className="calibration">
      <div className="radio-buttons">
        <Radio
          label={strings.calibrationPage.buttons.euMetrics}
          name="units"
          checked={unit === 'meter'}
          id="meter"
          value="meter"
          onChange={handleUnits}
          disabled={false}
        />
        <Radio
          label={strings.calibrationPage.buttons.usMetrics}
          name="units"
          checked={unit === 'feet'}
          id="feet"
          value="feet"
          onChange={handleUnits}
          disabled={false}
        />
      </div>
      <div className="calibration__buttons">
        <div className="calibration__buttons-inner">
          <button
            className="btn btn_submit btn_icon"
            disabled={width <= 110}
            type="button"
            onClick={() => handleRulerWidth(width - 10)}
          >
            <div className="icon-tuning icon-minus small" />
            <span className="icon-tuning icon-minus-5">5</span>
          </button>
          <button
            className="btn btn_submit btn_icon"
            disabled={width <= 110}
            type="button"
            onClick={() => handleRulerWidth(width - 1)}
          >
            <div className="icon-tuning icon-minus" />
          </button>
        </div>
        <button
          className="btn btn_submit btn_reset-icon"
          disabled={width === initialRulerWidth}
          type="button"
          onClick={handleReset}
        >
          <div className="icon-reset" />
          {strings.calibrationPage.buttons.reset}
        </button>
        <div className="calibration__buttons-inner">
          <button
            className="btn btn_submit btn_icon"
            disabled={width >= window.innerWidth - 33 || width >= 1100}
            type="button"
            onClick={() => handleRulerWidth(width + 1)}
          >
            <div className="icon-tuning icon-plus" />
          </button>
          <button
            className="btn btn_submit btn_icon"
            disabled={width >= window.innerWidth - 43 || width >= 1100}
            type="button"
            onClick={() => handleRulerWidth(width + 10)}
          >
            <div className="icon-tuning icon-plus small" />
            <div className="icon-tuning icon-plus-5">5</div>
          </button>
        </div>
      </div>
      <CalibrationRuler width={width} />
    </div>
  );
};

export default Calibration;
