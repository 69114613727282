import { call, delay, put } from 'redux-saga/effects';
// api
import { apiPatients } from '../../api/patients';
import { getPatientsActionReturnType } from '../../types/actions/patients';
// actions
import patientsAction from '../actions/patients';

export function* sagaGetPatients(action: getPatientsActionReturnType): any {
  try {
    yield delay(1);
    if (!action.data.doctorPatients) {
      yield put(patientsAction.getPatientsClear());
    }
    yield put(patientsAction.patientsLoading(true, 0));
    const patientsResponse = yield call(apiPatients.getPatients, action.data);
    yield put(patientsAction.patientsLoading(true, 90));
    yield put(patientsAction.patientsLoading(true, 100));
    yield delay(500);
    yield put(patientsAction.patientsLoading(false, 0));
    yield put(
      patientsAction.getPatients({
        ...patientsResponse.data,
        skip: action.data.skip,
        first: action.data.first,
        doctorPatients: action.data.doctorPatients
          ? [...action.data.doctorPatients, ...patientsResponse.data.doctorPatients]
          : patientsResponse.data.doctorPatients,
      }),
    );
  } catch (e) {
    console.log(e);
  }
}
