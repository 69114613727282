import React, { FC, useEffect, useState } from 'react';
// components
import CalibrationRuler from '../../../moleculs/calibrationRuler';
// types
import { appDataType } from '../../../../types/types';
// style
import './index.scss';
// helpers
import { useWindowSize } from '../../../../tools';
// localization
import strings from '../../../../localization';

interface CalibrationProps {
  appData: appDataType;
  unit: string;
  setRatio: (ratio: number) => void;
  setUnit: (unit: string) => void;
}

const CalibrationNew: FC<CalibrationProps> = ({ appData, setRatio, setUnit }: CalibrationProps) => {
  const [startWidth, setStartWidth] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);

  const initialRulerWidth = window.innerWidth <= 1100 ? 204 : 455;
  const initialRulerAbsoluteWidth = window.innerWidth <= 1100 ? 53.98 : 86;

  const [windowWidth] = useWindowSize();

  // set initial values from localStorage or default ones
  useEffect(() => {
    if (!appData.ratio) {
      handleStartValues();
    } else {
      setUnit(appData.unit);
      setWidth(appData.ratio * initialRulerAbsoluteWidth);
      setRatio(appData.ratio);
    }
  }, [appData]);

  // handle ruler start width when window width dynamicaly changes
  useEffect(() => {
    handleStartWidth(window.innerWidth);
  }, [windowWidth]);

  // handle ruler width with buttons
  const handleRulerWidth = (width: number) => {
    setWidth(width);
    setRatio(width / startWidth);
  };

  // handle values if localStorage is empty
  const handleStartValues = () => {
    setUnit('meter');
    setStartWidth(initialRulerAbsoluteWidth);
    setWidth(initialRulerWidth);
    setRatio(initialRulerWidth / initialRulerAbsoluteWidth);
  };

  // handle ruler start width method
  const handleStartWidth = (windowInnerWidth: number): void => {
    setStartWidth(windowInnerWidth <= 1100 ? 53.98 : 86);
  };

  // handle reset
  const handleReset = () => {
    setWidth(initialRulerWidth);
    setRatio(initialRulerWidth / startWidth);
  };

  return (
    <div className="calibrationNew">
      <div className="calibrationNew__buttons">
        <div className="calibrationNew__buttons-inner">
          <div className="calibrationNew__buttons-inner-btn">
            <button
              aria-label="-5"
              type="button"
              className="btn btn_submit btn_icon medium"
              disabled={width <= 110}
              onClick={() => handleRulerWidth(width - 10)}
            >
              <div className="icon-tuning icon-minus small" />
              <span className="icon-tuning icon-minus-5">5</span>
            </button>
          </div>
          <div className="calibrationNew__buttons-inner-btn">
            <button
              aria-label="minus"
              className="btn btn_submit btn_icon medium"
              disabled={width <= 110}
              type="button"
              onClick={() => handleRulerWidth(width - 1)}
            >
              <div className="icon-tuning icon-minus medium" />
            </button>
          </div>
        </div>
        <div className="calibrationNew__buttons-inner-btn">
          <button
            className="btn btn_submit btn_reset-icon medium"
            disabled={width === initialRulerWidth}
            type="button"
            onClick={handleReset}
          >
            <div className="icon-reset" />
            {strings.calibrationPage.buttons.reset}
          </button>
        </div>
        <div className="calibrationNew__buttons-inner">
          <div className="calibrationNew__buttons-inner-btn">
            <button
              aria-label="plus"
              className="btn btn_submit btn_icon medium"
              disabled={width >= window.innerWidth - 33 || width >= 1100}
              type="button"
              onClick={() => handleRulerWidth(width + 1)}
            >
              <div className="icon-tuning icon-plus" />
            </button>
          </div>
          <div className="calibrationNew__buttons-inner-btn">
            <button
              aria-label="+5"
              className="btn btn_submit btn_icon medium"
              disabled={width >= window.innerWidth - 43 || width >= 1100}
              type="button"
              onClick={() => handleRulerWidth(width + 10)}
            >
              <div className="icon-tuning icon-plus small" />
              <div className="icon-tuning icon-plus-5">5</div>
            </button>
          </div>
        </div>
      </div>
      <CalibrationRuler width={width} />
    </div>
  );
};

export default CalibrationNew;
