import React, { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// components
import AppTemplate from '../../templates/appTemplate';
import Calibration from '../../organisms/calibration';
// actions
import appAction from '../../../redux/actions/app';
// types
import { decodedTokenInfoType, stateType } from '../../../types/types';
// localization
import strings from '../../../localization';
// helpers
import { useWindowSize } from '../../../tools';
import appFlow from '../../../redux/actions/appFlow';
import CalibrationNew from '../../organisms/calibration/calibrationDark';

import './index.scss';
import { useTheme } from '../../../hooks/theme';
interface CalibrationPageProps {
  isIntegration: boolean;
  decodedData: decodedTokenInfoType;
}

const CalibrationPage: FC<CalibrationPageProps> = ({ isIntegration, decodedData }: CalibrationPageProps) => {
  const [unit, setUnit] = useState<string>('');
  const [ratio, setRatio] = useState<number>(0);

  const dispatch = useDispatch();
  const history = useHistory();
  const { isDark } = useTheme();
  const [windowWidth] = useWindowSize();

  const { appData, flowRoutes, flowIndex } = useSelector((state: stateType) => ({
    appData: state.app.app,
    flowRoutes: state.appFlow.routes,
    flowIndex: state.appFlow.flowIndex,
  }));

  const CalibrationComponentToRender = useMemo(() => (isDark ? CalibrationNew : Calibration), [isDark]);

  const title = useMemo(() => (isDark ? strings.calibrationPage.monitorCalibration : ''), [isDark]);

  const createSubmitObject = (
    decodedData: decodedTokenInfoType,
  ): { pathname: string; state?: { testType?: string; inputType?: string } } => {
    if (isIntegration) {
      dispatch(appFlow.setFlowIndex(flowIndex + 1));
      return {
        pathname: flowRoutes[flowIndex + 1],
        state: {
          testType: decodedData.testType,
          inputType: decodedData.inputType,
        },
      };
    }

    return { pathname: '/distance' };
  };

  // handleSubmit
  const handleSubmit = () => {
    const historyObj = createSubmitObject(decodedData);

    const callback = () => {
      history.push(historyObj);
    };

    const data = { ...appData, unit: decodedData.unitMeasure ? decodedData.unitMeasure : unit, ratio };
    dispatch(appAction.editAppDataAction(data, isIntegration, callback));
  };

  const renderDescription = () => {
    return (
      <div tabIndex={0}>
        <ol className="calibrationAppTemplate__list">
          {strings.calibrationPage.descriptionNew.map((item, index) => (
            <li key={index} className="light18x27">
              {item}
            </li>
          ))}
        </ol>
      </div>
    );
  };

  return (
    <AppTemplate
      handleSubmit={handleSubmit}
      cancelHidden={true}
      heading={title}
      renderDescription={isDark ? renderDescription : undefined}
      description={
        windowWidth <= 1100
          ? unit === 'meter'
            ? strings.calibrationPage.description.mobileInstructionMM
            : strings.calibrationPage.description.mobileInstructionInch
          : unit === 'meter'
          ? strings.calibrationPage.description.desktopInstructionMM
          : strings.calibrationPage.description.desktopInstructionInch
      }
      className=" small-content"
      decodedData={decodedData}
      isIntegration={isIntegration}
    >
      <CalibrationComponentToRender appData={appData} unit={unit} setRatio={setRatio} setUnit={setUnit} />
    </AppTemplate>
  );
};

export default CalibrationPage;
