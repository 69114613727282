import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';
// components
import TestTemplate from '../../templates/testTemplate';
import DoctorTest from '../../organisms/doctorTest';
// types
import { stateType, windowLocation } from '../../../types/types';
// helpers
import { useWindowSize } from '../../../tools';
// localization
import strings from '../../../localization';
import { useLocation } from 'react-router-dom';

interface DoctorTestPageProps {
  location: { state?: { roomId: string } };
  isIntegration: boolean;
}

const DoctorTestPage: FC<DoctorTestPageProps> = ({ isIntegration }: DoctorTestPageProps) => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isUserConnected, setIsUserConnected] = useState<boolean>(false);

  const { state } = useLocation<windowLocation>();
  const history = useHistory();
  const roomId = state?.roomId;
  const [windowWidth, windowHeight] = useWindowSize();
  const height = windowHeight > windowWidth && isMobile;

  // store
  const appLoading = useSelector((state: stateType) => state.app.loading);

  // redirect to connect page if user tried to get to test page without room number
  useEffect(() => {
    const redirect = () => {
      history.push('/connect');
    };

    !state && redirect();
  }, []);

  const handleSubmit = () => {
    history.push('/connect');
  };

  const handleCancel = () => {
    history.push('/connect');
  };

  return (
    <TestTemplate
      submitLabel={strings.testPage.disconnect}
      handleSubmit={handleSubmit}
      handleCancel={handleCancel}
      isSubmitDecline={true}
      disabled={!appLoading.status}
      submitHidden={!(!appLoading.status && isConnected)}
      isConnectStatusShown={!appLoading.status && isConnected}
      isUserConnected={isUserConnected}
      customUserType="Patient"
      height={height}
      className="test"
    >
      {roomId && (
        <DoctorTest
          appLoading={appLoading}
          isConnected={isConnected}
          setIsConnected={setIsConnected}
          roomId={roomId}
          isUserConnected={isUserConnected}
          setIsUserConnected={setIsUserConnected}
          isIntegration={isIntegration}
        />
      )}
    </TestTemplate>
  );
};

export default DoctorTestPage;
