import React, { FC } from 'react';
import { useSelector } from 'react-redux';
// components
import PatientInfo from '../../moleculs/patientInfo';
import PatientsList from '../patientsList';
import TestHistory from '../testHistory';
// types
import { stateType } from '../../../types/types';
// style
import './index.scss';

const DoctorHome: FC = () => {
  // store
  const patients = useSelector((state: stateType) => state.patients.patients);
  const patient = useSelector((state: stateType) => state.patient.patient);
  const testHistory = useSelector((state: stateType) => state.results.results);
  const unitMeasure = useSelector((state: stateType) => state.user.user.unitMeasure);
  const patientsLoading = useSelector((state: stateType) => state.patients.loading);
  const patientLoading = useSelector((state: stateType) => state.patient.loading);
  const resultsLoading = useSelector((state: stateType) => state.results.loading);
  const userType = useSelector((state: stateType) => state.user.user.userType);

  return (
    <div className="doctor-home">
      <div className="doctor-home__inner">
        <PatientsList
          patients={patients}
          patient={patient}
          patientsLoading={patientsLoading}
          disabled={patientLoading.status || resultsLoading.status}
        />
      </div>
      <div className="doctor-home__inner">
        {patient.patientName && (
          <>
            <PatientInfo patient={patient} />
            <TestHistory
              patientName={patient.patientName}
              patientId={patient?.userId}
              results={testHistory}
              unitMeasure={unitMeasure}
              loading={resultsLoading}
              disabled={patientsLoading.status || patientLoading.status}
              userType={userType}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DoctorHome;
