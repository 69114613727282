import React, { FC, useEffect, useRef, useState } from 'react';
import NoSleep from 'nosleep.js';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
//components
import Dyops from '../../atoms/dyops';
//actions
import dyopsAction from '../../../redux/actions/dyops';
// types
import { loadingType, roomDataType, stateType } from '../../../types/types';
// style
import './index.scss';
// helpers
import { useWindowSize } from '../../../tools';
import { handleDyopsControl } from '../../../tools/dyopsControl';
// websockets
import { webSocketConnect } from '../../../websocket/websocket';
// localization
import strings from '../../../localization';

interface DoctorTestProps {
  appLoading: loadingType;
  isConnected: boolean;
  setIsConnected: (isConnected: boolean) => void;
  isUserConnected: boolean;
  setIsUserConnected: (isUserConnected: boolean) => void;
  roomId: string;
  isIntegration: boolean;
}

const DoctorTest: FC<DoctorTestProps> = ({
  appLoading,
  setIsConnected,
  isConnected,
  isUserConnected,
  setIsUserConnected,
  roomId,
  isIntegration,
}: DoctorTestProps) => {
  const [randomNumbers, setRandomNumbers] = useState<Array<number>>([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const [windowWidth, windowHeight] = useWindowSize();
  const noSleep = new NoSleep();
  const socket = useRef<any>();
  const height = windowHeight > windowWidth && isMobile;

  // websockets
  const accessToken =
    isIntegration && sessionStorage.getItem('integrationToken')
      ? sessionStorage.getItem('integrationToken')
      : localStorage.getItem('accessToken')
      ? localStorage.getItem('accessToken')
      : '';
  const socketUrl = process.env.REACT_APP_API_SOCKET_URL || '';

  // store
  const dyops = useSelector((state: stateType) => state.dyops.dyops);
  const user = useSelector((state: stateType) => state.user.user);

  const doctorName = `${user?.firstName} ${user?.lastName}`;

  useEffect(() => {
    noSleep.enable();

    socket.current = webSocketConnect(socketUrl, roomId, accessToken ? accessToken : '');

    socket.current.on('connect', () => {
      console.log('connected to ' + socket.current.io.opts.query.roomId);
      setIsConnected(true);
      dispatch(dyopsAction.getDyopsAction(socket.current.io.opts.query.roomId));
    });

    socket.current.on('roomStatus', ({ room }: roomDataType) => {
      setIsUserConnected(room.users.find((item) => item.type === 'patient') ? true : false);
    });

    socket.current.on('disconnect', () => {
      console.log('disconnected from ' + socket.current.io.opts.query.roomId);
      dispatch(dyopsAction.clearDyops());
      setIsConnected(false);
    });

    return () => {
      noSleep.disable();
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    // dyops size debug for test and local servers
    (process.env.NODE_ENV === 'development' || window.location.origin === 'https://dyoptest-self.com') &&
      console.log('current size:', dyops.size);
  }, [dyops.steps]);

  const activeDyopRandom = (): 'left' | 'right' => {
    const random = Math.floor(Math.random() * (3 - 1) + 1);

    if (
      randomNumbers.length >= 3 &&
      randomNumbers.slice(randomNumbers.length - 3, randomNumbers.length).every((item) => item === random)
    ) {
      setRandomNumbers([...randomNumbers, random === 2 ? random - 1 : random + 1]);

      return (random === 2 ? random - 1 : random + 1) !== 2 ? 'right' : 'left';
    } else {
      setRandomNumbers([...randomNumbers, random]);

      return random % 2 !== 0 ? 'right' : 'left';
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement>) => {
    handleDyopsControl(
      dispatch,
      socket.current,
      dyops,
      e.currentTarget.id,
      history,
      isIntegration,
      activeDyopRandom,
      doctorName,
    );
  };

  return (
    <div className={`doctor-test${height ? ' rotate' : ''}`} style={height ? { width: `${window.innerHeight}px` } : {}}>
      {!appLoading.status && isConnected && (
        <>
          <div className="doctor-test__dyops">
            <Dyops
              diametr={windowWidth <= 1100 ? 53 : 66}
              label={true}
              dyops={dyops}
              isUserConnected={isUserConnected}
            />
          </div>
          <button
            disabled={((dyops.closeRightEye || dyops.closeLeftEye) && dyops.isStarted) || !isUserConnected}
            onClick={handleClick}
            id="start"
            className="dyops__start-btn btn btn_submit"
          >
            {!dyops.closeRightEye && !dyops.closeLeftEye
              ? strings.connectPage.labels.startTest
              : dyops.closeLeftEye
              ? strings.selfTest.testRight
              : strings.selfTest.testLeft}
          </button>
          <div
            className={`dyops__control-btns${
              !appLoading.status && isConnected && dyops.isStarted && isUserConnected ? ' visible' : ''
            }`}
          >
            <div className="buttons">
              <div className="buttons__item" id="pause" onClick={handleClick}>
                <div className="icon__wrapper">
                  <button className={`icon${!dyops.pause ? ' icon-pause' : ' icon-play'}`} />
                </div>
                <span>{!dyops.pause ? strings.testPage.controls.pause : strings.testPage.controls.start}</span>
              </div>
              <div className="buttons__item" id="increase" onClick={handleClick}>
                <div className="icon__wrapper">
                  <button className="icon icon-zoom-in" />
                </div>
                <span>{strings.testPage.controls.incorrect}</span>
              </div>
              <div className="buttons__item" id="decrease" onClick={handleClick}>
                <div className="icon__wrapper">
                  <button className="icon icon-zoom-out" />
                </div>
                <span>{strings.testPage.controls.correct}</span>
              </div>
              <div className="buttons__item" id="increase" onClick={handleClick}>
                <div className="icon__wrapper">
                  <button className="icon icon-help" />
                </div>
                <span>{strings.testPage.controls.notSure}</span>
              </div>
              <div className="buttons__item" id="rotation" onClick={handleClick}>
                <div className="icon__wrapper">
                  <button className="icon icon-rotation" />
                </div>
                <span>{strings.testPage.controls.direction}</span>
              </div>
              <div className="buttons__item" id="active" onClick={handleClick}>
                <div className="icon__wrapper">
                  <button className={`icon${dyops.active === 'left' ? ' icon-prev' : ' icon-next'}`} />
                </div>
                <span>
                  {dyops.active === 'right' ? strings.testPage.controls.toLeft : strings.testPage.controls.toRight}
                </span>
              </div>
              <div className="buttons__item" id="restart" onClick={handleClick}>
                <div className="icon__wrapper">
                  <button className="icon icon-restart" />
                </div>
                <span>{strings.testPage.controls.restart}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DoctorTest;
