import React, { FC, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import countryCodes from 'country-codes-list';
// components
import Input from '../../../atoms/input';
import Radio from '../../../atoms/radio';
// actions
import appAction from '../../../../redux/actions/app';
// types
import { registrationParamsType, stateType } from '../../../../types/types';
// localization
import strings from '../../../../localization';
// options
import { genders, titles, units } from '../../../../config/options';
// helpers
import { useWindowSize } from '../../../../tools';
// mapping
import { mapping } from '../../../../config/mapping';

const FormRegistration: FC = () => {
  const [values, setValues] = useState<registrationParamsType>({
    userType: 'Doctor',
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    birthDate: '',
    countryCode: '',
    phoneNumber: '',
    unitMeasure: '',
    title: '',
    sex: '',
  });
  const [date, setDate] = useState<{ month: string; day: string; year: string }>({ month: '', day: '', year: '' });

  // country codes
  const countriesLabels = countryCodes.customList('countryCode', '{countryNameEn}');
  const contryCodesList = countryCodes.customList('countryCode', '+{countryCallingCode}');
  const countryNameCodesList = countryCodes.customList('countryCode', '{countryCode}');

  const codes = mapping.buildCountryCodesList(countriesLabels, countryNameCodesList, contryCodesList);

  const history = useHistory();
  const dispatch = useDispatch();
  const [windowWidth] = useWindowSize();

  // store
  const appLoading = useSelector((state: stateType) => state.app.loading);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // date inputs handling
    if (name === 'year' || name === 'day' || name === 'month') {
      // date inputs validation, better rework later
      if (
        (name === 'year' && value.length <= 4 && !(date[name] == '0' && value === '00')) ||
        (value.length <= 2 && !(date[name] == '0' && value === '00'))
      ) {
        setDate({
          ...date,
          [name]: value,
        });
      }
    } else {
      // unit measurements clear when userType changes
      if (name === 'userType' && value === 'Patient') {
        setValues({ ...values, userType: value, unitMeasure: '' });
      }
      // usual inputs handling
      else {
        setValues({
          ...values,
          [name]: value,
        });
      }
    }
  };

  const handleSelect = ({ value }: { value: string }, { name }: { name: string }) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // setting birth date from 3 separate input fields, maybe rework later
    const birthDate = String(new Date(Object.values(date).join('/')).toISOString());

    const callback = () => {
      history.push({
        pathname: '/login',
        state: { values: { email: values.email, password: values.password } },
      });
    };

    const pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
    );

    // validation of select and email through alert
    if (!values.title) {
      alert('Title required');
    } else if (!values.countryCode) {
      alert('Country code required');
    } else if (!values.unitMeasure && values.userType === 'Doctor') {
      alert('Unit of measure required');
    } else if (!values.sex) {
      alert('Gender required');
    } else if (!pattern.test(values.email)) {
      alert('Please, enter proper email');
    } else {
      dispatch(appAction.registration({ ...values, birthDate }, callback));
    }
  };

  return (
    <>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__radio-wrapper">
          <h3 className="page-heading small">Choose User type</h3>
          <div className="form__radio-inner">
            <Radio
              label="I’m a Doctor"
              checked={values.userType === 'Doctor'}
              name="userType"
              id="doctor"
              value="Doctor"
              onChange={handleInput}
              disabled={appLoading.status}
              icon=" icon-doctor"
            />
            <Radio
              label="I’m a Patient"
              checked={values.userType === 'Patient'}
              name="userType"
              id="patient"
              value="Patient"
              onChange={handleInput}
              disabled={appLoading.status}
              icon=" icon-patient"
            />
          </div>
          <span className="page-description light">
            Hello {values.userType}! Please fill out the form below to get started
          </span>
        </div>
        <div className="form__row w-50">
          <div className="form__column">
            <Input
              label="Title"
              placeholder={windowWidth >= 540 ? 'Choose' : 'Choose option'}
              input={values.title}
              id="title"
              name="title"
              disabled={appLoading.status}
              handleChange={handleSelect}
              select={true}
              isSearchable={false}
              options={titles}
              required={true}
            />
            <Input
              label="Gender"
              placeholder={windowWidth >= 540 ? 'Choose' : 'Choose option'}
              input={values.sex}
              id="sex"
              name="sex"
              disabled={appLoading.status}
              handleChange={handleSelect}
              select={true}
              isSearchable={false}
              options={genders}
              required={true}
            />
          </div>
          <div className="form__column">
            <Input
              label="First name"
              placeholder="First name"
              input={values?.firstName}
              id="firstName"
              name="firstName"
              disabled={appLoading.status}
              handleChange={handleInput}
              icon="person"
              required={true}
              max={32}
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__column">
            <Input
              label="Last name"
              placeholder="Last name"
              input={values.lastName}
              id="lastName"
              name="lastName"
              disabled={appLoading.status}
              handleChange={handleInput}
              icon="person"
              required={true}
              max={32}
            />
          </div>
          <div className="form__column form__column_small">
            <Input
              label="Birth date"
              input={date}
              id="birthDate"
              name="birthDate"
              disabled={appLoading.status}
              handleChange={handleInput}
              inputType="date"
              required={true}
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__column form__column_small">
            <Input
              label="Country code"
              input={values.countryCode}
              id="countryCode"
              name="countryCode"
              disabled={appLoading.status}
              handleChange={handleSelect}
              select={true}
              isSearchable={true}
              options={codes}
              required={true}
            />
          </div>
          <div className="form__column">
            <Input
              label="Mobile number"
              placeholder="Mobile number"
              input={values.phoneNumber}
              id="phoneNumber"
              name="phoneNumber"
              disabled={appLoading.status}
              handleChange={handleInput}
              required={true}
              inputType="number"
            />
          </div>
        </div>
        {values.userType === 'Doctor' && (
          <div className="form__row">
            <div className="form__column">
              <Input
                label="Unit of measure"
                input={values.unitMeasure ? values.unitMeasure : ''}
                id="unitMeasure"
                name="unitMeasure"
                disabled={appLoading.status}
                handleChange={handleSelect}
                select={true}
                isSearchable={false}
                options={units}
                required={true}
              />
            </div>
          </div>
        )}
        <div className="form__row">
          <div className="form__column">
            <Input
              label="Email"
              placeholder="Email"
              input={values.email}
              id="email"
              name="email"
              disabled={appLoading.status}
              handleChange={handleInput}
              icon="email"
              required={true}
              inputType="email"
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__column">
            <Input
              label="Password"
              placeholder="Password"
              input={values.password}
              id="password"
              name="password"
              disabled={appLoading.status}
              handleChange={handleInput}
              icon="password"
              required={true}
              inputType="password"
              min={6}
            />
          </div>
        </div>
        <div className="form__btns">
          <div className="form__btns-item">
            <span>{strings.isAccount}?</span>
            <Link to="/login">{strings.signIn}</Link>
          </div>
          <button type="submit" className="btn btn_submit btn_wide" disabled={appLoading.status}>
            Register
          </button>
        </div>
      </form>
    </>
  );
};

export default FormRegistration;
