const en = {
  signIn: 'Login',
  email: 'Email',
  password: 'Password',
  patient: 'I am patient',
  doctor: 'I am doctor',
  isAccount: 'Already have an account',

  header: {
    navigation: {
      calibrate: 'Calibrate',
      setDistance: 'Set distance',
      testType: 'Test Type',
      inputType: 'Input Type',
    },
    headerMenu: {
      settings: 'Settings',
      aboutDyop: 'About Dyop',
      help: 'Help',
    },
  },
  calibrationPage: {
    description: {
      desktopInstructionInch:
        'Adjust the distance between the two lines by clicking the +/- button. The distance between them must be exactly 3.37 inches. To make the measurement you can use the long side of a credit card or debit card or a ruler.',
      desktopInstructionMM:
        'Adjust the distance between the two lines by clicking the +/- button. The distance between them must be exactly 85.6 mm. To make the measurement you can use the long side of a credit card or debit card or a ruler.',
      mobileInstructionInch:
        'Adjust the distance between the two lines by clicking the +/- button. The distance between them must be exactly 2.12 inches. To make the measurement you can use the short side of a credit card or debit card or a ruler.',
      mobileInstructionMM:
        'Adjust the distance between the two lines by clicking the +/- button. The distance between them must be exactly 53.98 mm. To make the measurement you can use the short side of a credit card or debit card or a ruler.',
      distanceInstruction:
        'Measure the distance between your monitor and where you stand. Preferably more than 2.5 meters',
    },
    descriptionNew: [
      'Place a loyalty card or bank card on the screen in the area indicated.',
      'Press the plus and minus buttons until the loyalty card, displayed on the screen, is the same size as the actual loyalty card.',
      'Press next to continue.',
    ],
    monitorCalibration: 'Monitor calibration.',
    buttons: {
      euMetrics: 'EU metrics',
      usMetrics: 'US metrics',
      reset: 'Reset',
    },
  },
  distancePage: {
    description:
      'Set the Viewing Distance from the monitor screen by either using a tape measure or measuring the steps from where you will be standing/sitting',
    buttons: {
      yes: 'Yes',
      no: 'No',
    },
    labels: {
      isTapeMeasure: 'Do you have a tape measure?',
      whatDistance: 'What is the distance?',
      meters: 'Meters',
      meter: 'Meter',
      feet: 'Feet',
      distance: 'Distance',
      steps: 'steps',
    },
    select: {
      chooseOption: 'Choose option',
      europe: 'Europe',
      usMen: 'USA & Canada (Men)',
      usWomen: 'USA & Canada (Women)',
      shoeMetrics: 'Shoe metrics',
      shoeSize: 'What’s your shoe size?',
    },
    distanceBlock: {
      heading: {
        viewingDistance: 'Set Viewing Distance',
        viewingSteps: 'Set Viewing Distance Steps',
      },
      description: {
        distanceDescription: 'Measure the distance from the monitor to where you will be standing/sitting',
        stepsDescription: 'Measure the number of steps from the monitor from where you will be standing/sitting',
      },
      chooseSize: 'Choose your foot size',
    },
  },

  footer: {
    nextStep: 'Next',
    back: 'Back',
  },
  testTypePage: {
    description: 'Select the Adult, Infant or Child format of the Dyop test',
    options: {
      adult: 'Adult',
      child: 'Child',
      infant: 'Infant',
    },
  },
  inputTypePage: {
    description: 'Select who will control the determination of the Dyop diameter sizes.',
    options: {
      doctorTest: 'Doctor will control the test',
      selfTest: 'I will control via my mobile device',
      oneDeviceTest: 'I will control via on screen buttons',
    },
  },
  connectPage: {
    heading: {
      headingDoctorTest: 'Doctor will control the test',
      headingScanQr: 'Scan QR',
      headingDoctorControl: 'Send code to your patient',
    },
    description: {
      doctorTestDescription:
        'The visual acuity/clarity endpoint is the smallest diameter Dyop where the direction of spinning can be detected. Tell the doctor whether it is the left Dyop or the Right Dyop and direction of spinning',
      scanQrDescription: 'Scan the QR code to proceed with the test.',
      scanQrDescriptionDark:
        'Scan this QR code to use your mobile device as an answering device for the vision screening test',
      doctorControlDescription:
        'Explain to the patient that a Dyop is a spinning visual target where the ability to detect the direction of spinning of the smallest diameter (arc width) Dyop is a benchmark indicator of visual acuity/clarity. When they log in to the program they need to enter the following code number and press Start',
    },
    labels: {
      enterDoctorCode: 'Enter the code provided by your doctor',
      enterCode: 'Enter code',
      startTest: 'Start test',
      sendCode: 'Send this code to your patient and press start',
      generateNew: 'Generate new',
      note: 'Note: please turn your device to the landscape mode before test starts',
    },
  },
  testPage: {
    patientIsNotConnected: 'Patient is not connected',
    patientConnected: 'Patient connected',
    doctorIsNotConnected: 'Doctor is not connected',
    doctorConnected: 'Doctor connected',
    secondDeviceConnected: 'Second device connected',
    secondDeviceIsNotConnected: 'Second device is not connected',
    disconnect: 'Disconnect',
    left: 'Left',
    right: 'Right',
    controls: {
      start: 'Start',
      pause: 'Pause',
      incorrect: 'Incorrect',
      correct: 'Correct',
      notSure: 'Not sure',
      direction: 'Direction',
      toLeft: 'To left',
      toRight: 'To right',
      restart: 'Restart',
      testLeftEye: 'Test Left Eye',
      testRightEye: 'Test Left Eye',
    },
    eyePrepare: {
      pleaseCloseLeft: 'Please, close left eye',
      pleaseCloseRight: 'Please, close right eye',
    },
  },
  selfTest: {
    heading: 'Scan QR code.',
    description: 'Scan the QR code to proceed with the test.',
    successScan: 'QR Code Scanning Complete',
    errorScan: 'Can’t Scan QR Code',
    successDescription:
      'Respond as to whether it is the left Dyop or the Right Dyop which is spinning and direction of the spinning.',
    closeLeft: 'Close left eye, testing right eye.',
    closeRight: 'Close right eye, testing left eye.',
    testRight: 'Test Right Eye',
    leftEyeTest: 'Left Eye Test',
    rightEyeTest: 'Right Eye Test',
    testLeft: 'Test Left Eye',
    pleaseTake: 'Please take',
    stepsAway: 'steps away from your screen and press TEST RIGHT EYE on your answering device to continue.',
    noLongerNeed:
      'You no longer need to enter any more information on your PC/Laptop and you should continue on your phone.',
    distanceYourSelf: 'Please distance yourself',
    fromMonitor: 'from the monitor and press TEST RIGHT EYE on your answering device to continue.',
    control: {
      description:
        'Respond as to whether it is the left Dyop or the right Dyop which is spinning or whether you can not tell (Don’t Know).',
      dontKnow: 'Don’t Know',
      restartTest: 'Restart test',
    },
    eyePrepare: {
      please: 'Please',
      take: 'take' /*take 2 steps away*/,
      move: 'move' /*move 2 meters away*/,
      heelToToe: 'steps (heel to toe)',
      awayFrom: 'away from monitor.',
    },
  },
};

export default en;
